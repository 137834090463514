import { FunctionComponent, useEffect, useRef, useState, useCallback, MutableRefObject } from "react";
import { useLocation } from 'react-router-dom';
import { usePreviousPath } from "../../hooks/usePreviousPath";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import Calendar from "../../components/General/Calendar";
import SideNavPost from "../../components/Merchant/SideNavPost";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "../../components/General/Slider";
import CompanyCard from "../../components/Merchant/CompanyCard";
import LimitSelector1 from "../../components/Merchant/LimitSelector1";
import LimitSelector2 from "../../components/Merchant/LimitSelector2";
import LimitSelector3 from "../../components/Merchant/LimitSelector3";
import CheckBoxText from "../../components/General/CheckBoxText";
import AccessibilityButtons from "../../components/Merchant/AccessibilityButtons";
import { ChevronLeft, ChevronDown, ChevronUp, CalendarClock, CalendarCheck } from "lucide-react";
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { CreateOfferRequest } from "../../api/OfferCreation";
import { CreatePromotionRequest } from "../../api/PromotionCreation";
import { DateTime } from "luxon";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface TaxCode {
  id: string;
  name: string;
  description: string;
}

interface MerchantProfile {
  openingHours: {
    schedule: {
      [key: string]: {
        closed: boolean;
      };
    };
  };
  address: {
    line1: string,
    line2: string,
    city: string,
    statg: string,
    postalCode: string,
    country: string
  };
}

interface Category {
  id: string;
  name: string;
}

type OfferDataType = {
    name: string;
    description: string;
    additionalInfo?: string;
    price: number;
    locations: {
      name: string;
      address: string;
      city: string;
      country: string;
      postalCode: string;
      region: string;
      latitude: number;
      longitude: number;
    }[];
    categories: { id: string }[];
    stock?: number;
    hasUnlimitedStock: boolean;
    highlights: { id: string }[];
    recurrencePattern?: any;
    displayImageReferences: string[];
    taxCode: string;
};

interface FormErrors {
  name?: string;
  price?: string;
  categories?: string;
  description?: string;
  additionalInfo?: string;
  location?: string;
  promotionName?: string;
  promotionDescription?: string;
  discountValue?: string;
  minimumPurchaseAmount?: string;
  maxUsageCount?: string;
  promotionDates?: string;
  time?: string;
  dates?: string;
  taxCode?: string;
}

const MerchantHubPost1: React.FC = () => {
  const { offerId } = useParams<{ offerId?: string }>();
  const isFirstRender = useRef(true);

    const previousPath = usePreviousPath();
    const location = useLocation();
  
    // useEffect(() => {
    //   if (previousPath === '/merchant-hub-post-2') {
    //     // You've navigated back from /merchant-hub-post-2
    //     console.log("Navigated back from /merchant-hub-post-2");
    //     // Add any specific logic you need here
    //   }
    // }, [previousPath]);

  const removeCachedData = () => {
    localStorage.removeItem('offerData');
    localStorage.removeItem('uploadedImages');
    localStorage.removeItem('promotionData');
  }

  useEffect(() => {
    if (!offerId && previousPath !== '/merchant-hub-post-2') {
      removeCachedData();
    }
  }, [offerId]);


  const navigate = useNavigate();
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [additionalInfoCharCount, setAdditionalInfoCharCount] = useState(0);
  const [taxCodes, setTaxCodes] = useState<TaxCode[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [regularPrice, setRegularPrice] = useState<string>('');
  const [voucherCap, setVoucherCap] = useState<string>('');
  const [dealPrice, setDealPrice] = useState(0);
  const [sliderValue, setSliderValue] = useState<string>('20');
  const [totalIncome, setTotalIncome] = useState(0);
  const [useBusinessAddress, setUseBusinessAddress] = useState(false);
  const [newAddress, setNewAddress] = useState('');
  const [calendarFromShown, setCalendarFromShown] = useState(false);
  const [calendarToShown, setCalendarToShown] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [limit, setLimit] = useState<string>('1');
  const [giftLimit, setGiftLimit] = useState<string>('1');
  const [errors, setErrors] = useState<FormErrors>({});
  const [startTime, setStartTime] = useState<DateTime | null>(null);
  const [endTime, setEndTime] = useState<DateTime | null>(null);
  const [selectedHighlightIds, setSelectedHighlightIds] = useState<string[]>([]);
  const [merchantProfile, setMerchantProfile] = useState<MerchantProfile | null>(null);
  const [frequency, setFrequency] = useState<string>('ONCE');
  const [apiError, setApiError] = useState<string | null>(null);
  const [manualDealPrice, setManualDealPrice] = useState<string>('');
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
  const addressInputRef = useRef<any>(null);
  const [isValidGooglePlace, setIsValidGooglePlace] = useState(false);
  const [clickedButtons, setClickedButtons] = useState<{ [key: string]: boolean }>({
    oneTime: false,
    recurring: false
  });
  const [offerData, setOfferData] = useState<CreateOfferRequest>({
  offer: {
    name: '',
    description: '',
    locations: [],
    categoryIds: [],
    price: 0,
    taxCode: '',
    stock: 0,
    hasUnlimitedStock: false,
    tags: [],
    highlightIds: [],
  },
    images: [],
  });
  const [promotionData, setPromotionData] = useState<Partial<CreatePromotionRequest>>({
  });

  //Calendar Utils
  const today: Date = new Date();
  const dateString: string = today.toISOString().split('T')[0]; // Format: "YYYY-MM-DD"
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromMonth, setFromMonth] = useState(new Date().getMonth());
  const [toMonth, setToMonth] = useState((new Date().getMonth() + 1) % 12);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDealType, setselectedDealType] = useState<'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE' | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  const handleNavigate = useCallback((route: string) => {
    navigate(route);
  }, [navigate]);

  const handleHighlightsChange = (highlightIds: string[]) => {
    setOfferData(prevData => {
      const updatedData = {
        ...prevData,
        offer: {
          ...prevData.offer,
          highlightIds: highlightIds,
        }
      };
      setSelectedHighlightIds(highlightIds);
      localStorage.setItem('offerData', JSON.stringify(updatedData));
      return updatedData;
    });
  };
  
  const fetchOfferData = useCallback(async () => {
    if (offerId) {
      try {
        const [offerResponse, promotionResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
          }),
          axios.get(`${API_BASE_URL}/v1/promotions/by-offer/${offerId}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
          })
        ]);
  
        const offerData = offerResponse.data;
        const promotionData = promotionResponse.data[promotionResponse.data.length - 1];
        setOfferData({
          offer: {
            name: offerData.name || '',
            description: offerData.description || '',
            additionalInfo: offerData.additionalInfo,
            price: offerData.price || 0,
            locations: offerData.locations.map((location: any) => ({
              name: location.name || "Custom Address",
              address: location.address || "No address specified",
              city: location.city || "Fort Myers",
              country: location.country || "USA",
              postalCode: location.postalCode || "00000",
              region: location.region || "Florida",
              latitude: location.latitude || 0,
              longitude: location.longitude || 0,
            })),
            categoryIds: offerData.categories.map((category: { id: string }) => category.id),
            stock: offerData.stock,
            hasUnlimitedStock: offerData.hasUnlimitedStock,
            highlightIds: offerData.highlights ? offerData.highlights.map((highlight: { id: string }) => highlight.id) : [],
            recurrencePattern: offerData.recurrencePattern,
            taxCode: offerData.taxCode,
          },
          images: offerData.displayImageReferences,
        });
        setPromotionData(promotionData);
        setIsValidGooglePlace(true);
        // Set other state variables
        setStartTime(offerData.recurrencePattern?.startTime 
          ? DateTime.fromISO(offerData.recurrencePattern.startTime) 
          : null);
        setEndTime(offerData.recurrencePattern?.endTime 
          ? DateTime.fromISO(offerData.recurrencePattern.endTime) 
          : null);
        setFromDate(offerData.recurrencePattern?.startDate ? new Date(offerData.recurrencePattern.startDate) : null);
        setToDate(offerData.recurrencePattern?.endDate ? new Date(offerData.recurrencePattern.endDate) : null);
        setVoucherCap(offerData.stock?.toString() || '');
        setFrequency(offerData.recurrencePattern?.frequency || 'ONCE');
        toggleButton(offerData.recurrencePattern.frequency);
        if (offerData.locations && offerData.locations.length > 0) {
          const location = offerData.locations[0];
          setNewAddress(location.address || '');
          // console.log("Address set as:", location.address);
        }
  
        if (promotionData) {
          if (promotionData.type === 'PERCENTAGE_DISCOUNT') {
            // console.log("Setting promoData button")
            setselectedDealType('PERCENTAGE_DISCOUNT');
            toggleDealType('PERCENTAGE_DISCOUNT');
          } else if (promotionData.type === 'BUY_X_GET_Y_FREE') {
            setselectedDealType('BUY_X_GET_Y_FREE');
            toggleDealType('BUY_X_GET_Y_FREE');
          }
          setLimit(promotionData.minimumPurchaseAmount?.toString() || '1');
          setGiftLimit(promotionData.discountValue?.toString() || '1');
        }
  
        // Store the data in localStorage
        localStorage.setItem('offerData', JSON.stringify({
          offer: {
            name: offerData.name,
            description: offerData.description,
            additionalInfo: offerData.additionalInfo,
            price: offerData.price,
            locations: offerData.locations || [],
            categoryIds: offerData.categories.map((category: { id: string }) => category.id),
            stock: offerData.stock,
            hasUnlimitedStock: offerData.hasUnlimitedStock,
            highlightIds: offerData.highlights ? offerData.highlights.map((highlight: { id: string }) => highlight.id) : [],
            recurrencePattern: offerData.recurrencePattern,
            taxCode: offerData.taxCode
          },
          images: offerData.displayImageReferences,
        }));
        localStorage.setItem('promotionData', JSON.stringify(promotionData));
  
      } catch (error) {
        console.error('Error fetching offer data:', error);
      }
    }
  }, [offerId]);

  //Update Deal Buttons
  useEffect(() => {
    if (promotionData && promotionData.type) {
      setselectedDealType(promotionData.type);
      setLimit(promotionData.minimumPurchaseAmount?.toString() || '1');
      setGiftLimit(promotionData.discountValue?.toString() || '1');
    }
  }, [promotionData]);
  
  useEffect(() => {
    if (offerId) {
      fetchOfferData();
    } else {
      const savedOfferData = localStorage.getItem('offerData');
      const savedPromotionData = localStorage.getItem('promotionData');
      if (savedOfferData) {
        const parsedOfferData = JSON.parse(savedOfferData);
        setOfferData(parsedOfferData);
        
        // Extract and set the address
        if (parsedOfferData.offer && parsedOfferData.offer.locations && parsedOfferData.offer.locations.length > 0) {
          const location = parsedOfferData.offer.locations[0];
          setNewAddress(location.address);
          setUseBusinessAddress(location.name === "Business Address");
          setIsValidGooglePlace(true);
        } else {
          setUseBusinessAddress(false);
          setUseBusinessAddress(false);
        }
  
        // Restore other state variables
        if (parsedOfferData.offer) {
          setStartTime(parsedOfferData.offer.recurrencePattern?.startTime 
            ? DateTime.fromISO(parsedOfferData.offer.recurrencePattern.startTime) 
            : null);
          setEndTime(parsedOfferData.offer.recurrencePattern?.endTime 
            ? DateTime.fromISO(parsedOfferData.offer.recurrencePattern.endTime) 
            : null);
          setSelectedHighlightIds(parsedOfferData.offer.highlightIds || []);
          setFromDate(parsedOfferData.offer.recurrencePattern?.startDate ? new Date(parsedOfferData.offer.recurrencePattern.startDate) : null);
          setToDate(parsedOfferData.offer.recurrencePattern?.endDate ? new Date(parsedOfferData.offer.recurrencePattern.endDate) : null);
          setVoucherCap(parsedOfferData.offer.stock?.toString() || '');
          setFrequency(parsedOfferData.offer.recurrencePattern?.frequency || 'ONCE');
          toggleButton(parsedOfferData.offer.recurrencePattern?.frequency || 'ONCE');
        }
      }
      if (savedPromotionData) {
        const parsedPromotionData = JSON.parse(savedPromotionData);
        setPromotionData(parsedPromotionData);
        if (parsedPromotionData.type === 'PERCENTAGE_DISCOUNT') {
          setselectedDealType('PERCENTAGE_DISCOUNT');
          toggleDealType('PERCENTAGE_DISCOUNT');
        } else if (parsedPromotionData.type === 'BUY_X_GET_Y_FREE') {
          setselectedDealType('BUY_X_GET_Y_FREE');
          toggleDealType('BUY_X_GET_Y_FREE');
        }
        setLimit(parsedPromotionData.minimumPurchaseAmount?.toString() || '1');
        setGiftLimit(parsedPromotionData.discountValue?.toString() || '1');
      }
    }
  }, [offerId, fetchOfferData]);

  //Ghetto Fix: these needed extra attention
  useEffect(() => {
    if (offerData.offer?.highlightIds) {
      setSelectedHighlightIds(offerData.offer.highlightIds);
    }
  }, [offerData]);


  const setAddressFromSavedData = (location: any) => {
    if (location && location.address) {
      // console.log("Location and address present!")
      setNewAddress(location.address);

      setOfferData(prevData => ({
        ...prevData,
        offer: {
          ...prevData.offer,
          locations: [{
            name: location.name || "Custom Address",
            address: location.address,
            city: location.city || "",
            country: location.country || "",
            postalCode: location.postalCode || "",
            region: location.region || "",
            latitude: location.latitude || 0,
            longitude: location.longitude || 0,
          }]
        }
      }));
    }
  };

  useEffect(() => {
    const savedOfferData = localStorage.getItem('offerData');
    const savedPromotionData = localStorage.getItem('promotionData');
    // console.log("savedPromotionData", savedPromotionData)
    if (savedOfferData) {
      const parsedOfferData = JSON.parse(savedOfferData);
      setOfferData(parsedOfferData);
      // Extract and set the address
      if (parsedOfferData.offer && parsedOfferData.offer.locations && parsedOfferData.offer.locations.length > 0) {
        const location = parsedOfferData.offer.locations[0];
        setAddressFromSavedData(location);
        setUseBusinessAddress(location.name === "Business Address");
      } else {
        // setNewAddress("");
        setUseBusinessAddress(false);
      }
      if (parsedOfferData.offer) {
        setIsValidGooglePlace(true);
        setStartTime(parsedOfferData.offer.recurrencePattern?.startTime || '');
        setEndTime(parsedOfferData.offer.recurrencePattern?.endTime || '');
        setFromDate(parsedOfferData.offer.recurrencePattern?.startDate ? new Date(parsedOfferData.offer.recurrencePattern.startDate) : null);
        setToDate(parsedOfferData.offer.recurrencePattern?.endDate ? new Date(parsedOfferData.offer.recurrencePattern.endDate) : null);
        setVoucherCap(parsedOfferData.offer.stock?.toString() || '');
        setUseBusinessAddress(parsedOfferData.offer.locations?.[0]?.name === "Business Address");
        parsedOfferData.offer.recurrencePattern?.frequency == "ONCE" ? toggleButton("ONCE") : toggleButton("RECURRING");
      }
      if (parsedOfferData.offer.locations) {
        handlePlaceSelected(parsedOfferData.offer.locations[0].address)
      }
    }
    if (savedPromotionData && !promotionData.discountValue && offerData.offer.price > 0) {
      setPromotionData(JSON.parse(savedPromotionData));
      // console.log("promotionData set")
    }
    //Avoid runtime errors by checking defined first
    if(promotionData && promotionData.type) {
      if (promotionData.type === 'BUY_X_GET_Y_FREE') {
        toggleDealType('BUY_X_GET_Y_FREE')
        setselectedDealType("BUY_X_GET_Y_FREE");
        setLimit(promotionData.minimumPurchaseAmount?.toString() || '1');
        setGiftLimit(promotionData.discountValue?.toString() || '1');
        setPromotionData(prevData => ({
          ...prevData,
          type: 'BUY_X_GET_Y_FREE',
          minimumPurchaseAmount: promotionData.minimumPurchaseAmount || 1,
          discountValue: promotionData.discountValue || 1,
        }));
      }
      if (promotionData.type === 'PERCENTAGE_DISCOUNT') {
        toggleDealType('PERCENTAGE_DISCOUNT');
        setselectedDealType("PERCENTAGE_DISCOUNT");
        setGiftLimit(promotionData.discountValue?.toString() || '1');
        setPromotionData(prevData => ({
          ...prevData,
          type: 'PERCENTAGE_DISCOUNT',
          discountValue: promotionData.discountValue || 1,
        }));
        let storedDiscountValue = promotionData.discountValue || 0
        setPromotionData(prevData => {
          const newData = { ...prevData, discountValue: storedDiscountValue };
          // Recalculate deal price immediately
          const price = offerData.offer?.price || 0;
          const calculatedDealPrice = price * (1 - storedDiscountValue / 100);
          setDealPrice(calculatedDealPrice);
          return newData;
        });
      }
    }
  }, []);

  useEffect(() => {
    const fetchTaxCodes = async () => {
      try {
        const response = await axios.get<TaxCode[]>(`${API_BASE_URL}/v1/merchants/tax-codes`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
        });
        setTaxCodes(response.data);
      } catch (error) {
        console.error('Error fetching tax codes:', error);
      }
    };
  
    fetchTaxCodes();
  }, []);

  const handleInputBlur = async () => {
    // console.log("Blur handler triggered");
    // console.log(`!isValidGooglePlace: ${!isValidGooglePlace}`)
    // console.log(`newAddress: ${newAddress}`)
    if (!isValidGooglePlace) {
      // console.log("Processing blur with value:", newAddress);
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      
      try {
        const response = await new Promise((resolve, reject) => {
          autocompleteService.getPlacePredictions({
            input: newAddress,
            types: ['address']
          }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
              resolve(predictions);
            } else {
              reject(status);
            }
          });
        });
        
        if (response && response.length > 0) {
          const firstPrediction = response[0];
          const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
          
          placesService.getDetails({
            placeId: firstPrediction.place_id,
            fields: ['formatted_address', 'geometry', 'address_components']
          }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
              handlePlaceSelected(place);
            }
          });
        }
      } catch (error) {
        console.error('Error getting place predictions:', error);
      }
    }
  };  

  const handleTaxCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOfferData(prevData => ({
      ...prevData,
      offer: {
        ...prevData.offer,
        taxCode: e.target.value
      }
    }));
  };

  const onGoBackClick = () => {
    if (offerId)
      removeCachedData()
    navigate("/merchant-hub-deals");
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    const newTime = DateTime.local().set({ hour: hours, minute: minutes });
    setStartTime(newTime);
  };
  
  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    const newTime = DateTime.local().set({ hour: hours, minute: minutes });
    setEndTime(newTime);
  };

  const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setApiError(null);

    //GHETTO SOLUTION
    setNewAddress(offerData.offer.locations[0].address)

    if(promotionData && promotionData.discountValue) {
      if(promotionData.type == "BUY_X_GET_Y_FREE") {
        if(!promotionData.discountValue)
          promotionData.discountValue = 1;
        if(!promotionData.minimumPurchaseAmount)
          promotionData.minimumPurchaseAmount = 1;
      }

      if(!promotionData.maxUsageCount) {
        promotionData.maxUsageCount = 100
      }
    }

    if (validateInputs()) {
    const openDays = merchantProfile?.openingHours.schedule
      ? Object.entries(merchantProfile.openingHours.schedule)
          .filter(([_, { closed }]) => !closed)
          .map(([day]) => day.toUpperCase())
      : [];

    const updatedOfferData = {
      ...offerData,
      offer: {
        ...offerData.offer,
        hasUnlimitedStock: !voucherCap || voucherCap === '0',
        stock: voucherCap ? parseInt(voucherCap) : 10,
        highlightIds: selectedHighlightIds,
        categoryIds: offerData.offer?.categoryIds || [],
        additionalInfo: offerData.offer.additionalInfo || "-",
        locations: offerData.offer?.locations || [],
        recurrencePattern: {
          ...offerData.offer?.recurrencePattern,
          startTime: startTime ? startTime : undefined,
          endTime: endTime ? endTime : undefined,
          frequency: frequency,
          daysOfWeek: openDays.length > 0 ? openDays : undefined,
          startDate: fromDate?.toISOString().split('T')[0],
          endDate: toDate?.toISOString().split('T')[0],
        }
      }
    };

    localStorage.setItem('offerData', JSON.stringify(updatedOfferData));

    //GHETTO SOLUTION
    if(promotionData && promotionData.type == "BUY_X_GET_Y_FREE") {
      if(!promotionData.discountValue)
        promotionData.discountValue = 1;
      if(!promotionData.minimumPurchaseAmount)
        promotionData.minimumPurchaseAmount = 1;
    }

    if(promotionData && !promotionData.maxUsageCount) {
      promotionData.maxUsageCount = 100
    }
    const updatedPromotionData = selectedDealType ? {
      ...promotionData,
      name: offerData.offer?.name || '',
      description: offerData.offer?.description || '',
      maxUsageCount: voucherCap === '' ? 10 : (parseInt(voucherCap) ? parseInt(voucherCap) : 10),
      type: selectedDealType,
      taxCode: offerData.offer.taxCode,
      minimumPurchaseAmount: selectedDealType === 'BUY_X_GET_Y_FREE' ? (parseInt(limit) || 1) : (promotionData.minimumPurchaseAmount || 1),
      discountValue: selectedDealType === 'PERCENTAGE_DISCOUNT' ? promotionData.discountValue : (parseInt(giftLimit) || 1),
      locations: [{
        name: offerData.offer.locations[0].name,
        address: offerData.offer.locations[0].address,
        city: offerData.offer.locations[0].city,
        country: offerData.offer.locations[0].country,
        postalCode: offerData.offer.locations[0].postalCode,
        region: offerData.offer.locations[0].region,
        latitude: offerData.offer.locations[0].latitude,
        longitude: offerData.offer.locations[0].longitude,
      }],
      recurrencePattern: {
        ...offerData.offer?.recurrencePattern,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        frequency: frequency,
        daysOfWeek: openDays.length > 0 ? openDays : undefined,
        startDate: fromDate?.toISOString().split('T')[0],
        endDate: toDate?.toISOString().split('T')[0],
      }
    } : null;

    if (updatedPromotionData) {
      localStorage.setItem('promotionData', JSON.stringify(updatedPromotionData));
    } else {
      // localStorage.removeItem('promotionData');
    }
  
    localStorage.setItem('offerData', JSON.stringify(updatedOfferData));
    // localStorage.setItem('promotionData', JSON.stringify(promotionData));
    
    if (offerId) {
      navigate(`/merchant-hub-post-2/${offerId}`);
    }
    else {
      navigate(`/merchant-hub-post-2`);
    }
   } 
  };

  const handleMonthChange = (offset: number) => {
    const newDate = new Date(currentYear, currentMonth + offset);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  const calculateDiscountValue = (originalPrice: number, dealPrice: number) => {
    if (originalPrice <= 0 || dealPrice >= originalPrice) return 0;
    return ((originalPrice - dealPrice) / originalPrice) * 100;
  };
  
  useEffect(() => {
    const price = offerData.offer?.price || 0;
    const discountValue = promotionData?.discountValue ?? 0;
    const calculatedDealPrice = price * (1 - discountValue / 100);
    setDealPrice(calculatedDealPrice);
    setManualDealPrice(calculatedDealPrice.toFixed(2));
  }, [offerData.offer?.price, promotionData?.discountValue, voucherCap]);

  const handleUseBusinessAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseBusinessAddress(e.target.checked);
  };
  
  const handleSetNewAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseBusinessAddress(!e.target.checked);
  };
  
  const handleNewAddressInput = (address: string) => {
    setNewAddress(address);
    
    // Update offerData with the new location
    setOfferData(prevData => ({
      ...prevData,
      offer: {
        ...prevData.offer,
        locations: [{
          name: "Custom Address",
          address: address,
          city: "Orlando",
          country: "USA",
          postalCode: "00000",
          region: "Florida",
          latitude: 0,
          longitude: 0,
        }]
      }
    }));
  };

  const handleCalendarFromClick = () => {
    setCalendarFromShown(!calendarFromShown);
  }

  const handleCalendarToClick = () => {
    setCalendarToShown(!calendarToShown);
  }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Category[]>(`${API_BASE_URL}/v1/catalog/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    const fetchMerchantProfile = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setMerchantProfile(response.data);
      } catch (error) {
        console.error('Error fetching merchant profile:', error);
      }
    };

    fetchMerchantProfile();
    fetchCategories();
  }, []);

  const handleCategoryChange = (categoryId: string) => {
    setOfferData(prevData => {
      const updatedOffer = { ...prevData.offer };
      const currentCategoryIds = updatedOffer.categoryIds || [];
  
      let newCategoryIds: string[];
      if (currentCategoryIds.includes(categoryId)) {
        newCategoryIds = currentCategoryIds.filter(id => id !== categoryId);
      } else if (currentCategoryIds.length < 3) {
        newCategoryIds = [...currentCategoryIds, categoryId];
      } else {
        newCategoryIds = currentCategoryIds;
      }
  
      const newData = {
        ...prevData,
        offer: {
          ...updatedOffer,
          categoryIds: newCategoryIds
        }
      };
  
      // Save the updated data to localStorage
      localStorage.setItem('offerData', JSON.stringify(newData));
  
      return newData;
    });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddress(e.target.value);
    setIsValidGooglePlace(false); // Reset valid flag when input changes
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('offer.')) {
      let updatedValue = value;
      if (name === 'offer.price') {
        if (/^(0|[1-9]\d*)(\.\d{0,2})?$/.test(value) || value === '') {
          updatedValue = value;
        } else {
          return;
        }
      }
      setOfferData(prevData => {
        const newData = {
          ...prevData,
          offer: {
            ...prevData.offer,
            [name.split('.')[1]]: updatedValue,
          }
        };
        localStorage.setItem('offerData', JSON.stringify(newData));
  
        // Update character counts
        if (name === 'offer.description') {
          setDescriptionCharCount(updatedValue.length);
        } else if (name === 'offer.additionalInfo') {
          setAdditionalInfoCharCount(updatedValue.length);
        }
  
        return newData;
      });
    } else if (name.startsWith('promotion.')) {
      setPromotionData(prevData => {
        const newData = {
          ...prevData,
          [name.split('.')[1]]: value,
        };
        localStorage.setItem('promotionData', JSON.stringify(newData));
        return newData;
      });
    }
  };

  const handlePriceBlur = () => {
    setOfferData(prevData => {
      const price = prevData.offer?.price;
      if (price !== undefined) {
        let formattedPrice: string | number = price.toString();
        if (formattedPrice === '' || formattedPrice === '0') {
          formattedPrice = '0.00';
        } else {
          formattedPrice = Number(formattedPrice).toFixed(2);
        }
        const newData = {
          ...prevData,
          offer: {
            ...prevData.offer,
            price: Number(formattedPrice),
          }
        };
        localStorage.setItem('offerData', JSON.stringify(newData));
        return newData;
      }
      return prevData;
    });
  };

  const handleFromDateSelect = (date: Date) => {
    setFromDate(date);
    // Set toMonth to match fromMonth
    setToMonth(date.getMonth());
    setOfferData(prevData => ({
      ...prevData,
      offer: {
        ...prevData.offer,
        recurrencePattern: {
          ...prevData.offer?.recurrencePattern,
          startDate: date.toISOString().split('T')[0],
        }
      }
    }));
    handleCalendarFromClick();
  };
  
  const toggleDealType = useCallback((dealType: 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE' | null) => {
    setselectedDealType(prevType => {
      if (prevType === dealType) {
        // If the same type is selected, clear the promotion data
        // setPromotionData({});
        return null;
      } else {
        // If a different type is selected, update the promotion data
        setPromotionData(prevData => ({
          ...prevData,
          type: dealType as 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE' | undefined,
          discountValue: dealType === 'PERCENTAGE_DISCOUNT' ? 50 : prevData.discountValue,
        }));
        return dealType;
      }
    });
  }, []);

  //Ghetto solution only for click events for now
  const clickToggleDealType = useCallback((dealType: 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE' | null) => {
    setselectedDealType(prevType => {
      if (prevType === dealType) {
        setPromotionData({});// If the same type is selected, clear the promotion data
        localStorage.removeItem("promotionData")
        return null;
      } else {
        setPromotionData(prevData => ({
          ...prevData,
          type: dealType as 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE' | undefined,
          discountValue: dealType === 'PERCENTAGE_DISCOUNT' ? 50 : prevData.discountValue ?? 1,
        }));
        return dealType;
      }
    });
  }, []);
  
  const handleToDateSelect = (date: Date) => {
    setToDate(date); // Add this line to update the toDate state
    setOfferData(prevData => ({
      ...prevData,
      offer: {
        ...prevData.offer,
        recurrencePattern: {
          ...prevData.offer?.recurrencePattern,
          endDate: date.toISOString().split('T')[0],
        }
      }
    }));
    handleCalendarToClick();
  };

  const handleFromMonthChange = (offset: number) => {
    const newDate = new Date(year, fromMonth + offset);
    setFromMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  };
  
  const handleToMonthChange = (offset: number) => {
    const newDate = new Date(year, toMonth + offset);
    setToMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  };

  const toggleButton = (button: string) => {
    setClickedButtons({
      oneTime: button === 'ONCE',
      recurring: button !== 'ONCE'
    });
  };

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFrequency = e.target.value;
    setFrequency(newFrequency);
    setOfferData(prevData => ({
      ...prevData,
      offer: {
        ...prevData.offer,
        recurrencePattern: {
          ...prevData.offer?.recurrencePattern,
          frequency: newFrequency,
        }
      }
    }));
  };

  const handlePlaceSelected = (place: google.maps.places.PlaceResult | any) => {
    // If we receive a prediction from keyboard selection
    if (place?.description) {
      // Create a Places Service to get full place details
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
      
      placesService.getDetails({
        placeId: place.place_id,
        fields: ['formatted_address', 'geometry', 'address_components']
      }, (detailedPlace, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && detailedPlace) {
          updateLocationWithPlace(detailedPlace);
        }
      });
    } 
    // Regular mouse selection
    else if (place?.formatted_address) {
      updateLocationWithPlace(place);
    }
  };
  
  const updateLocationWithPlace = (place: google.maps.places.PlaceResult) => {
    if (!place || !place.formatted_address) {
      console.error('Invalid place data:', place);
      return;
    }
  
    const addressComponents = place.address_components || [];
    const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
    const country = addressComponents.find(component => component.types.includes('country'))?.long_name || '';
    const postalCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name || '';
    const region = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
    setIsValidGooglePlace(true);
  
    const newLocation = {
      name: "Custom Address",
      address: place.formatted_address,
      city,
      country,
      postalCode,
      region,
      latitude: place.geometry?.location?.lat() || 0,
      longitude: place.geometry?.location?.lng() || 0,
    };
  
    setOfferData(prevData => {
      const newData = {
        ...prevData,
        offer: {
          ...prevData.offer,
          locations: [newLocation]
        }
      };
      localStorage.setItem('offerData', JSON.stringify(newData));
      return newData;
    });
    setNewAddress(place.formatted_address);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const formattedDate = (date: DateTime<boolean> | null) => {
    const formattedDate = date ? DateTime.fromISO(date.toString()) : null
    return formattedDate?.toFormat("HH:mm");
  };

const validateInputs = () => {
  const newErrors: FormErrors = {};

  // Offer validations
  if (!offerData.offer?.name) {
    newErrors.name = 'Offer name is required';
  }

  if (offerData.offer?.price === undefined || offerData.offer.price === null) {
    newErrors.price = 'Price must be specified';
  }

  if (offerData.offer?.price > 0 && offerData.offer?.price < 10) {
    newErrors.price = 'Price must be either Free or at least $10';
  }

  if (offerData.offer?.price > 0 && offerData.offer?.price > 69000000) {
    newErrors.price = 'Price must below $69Milion';
  }

  if (!offerData.offer?.categoryIds || offerData.offer.categoryIds.length === 0) {
    newErrors.categories = 'At least one category must be selected';
  }

  if (!offerData.offer?.description) {
    newErrors.description = 'Description is required';
  }

  if (offerData.offer?.description && offerData.offer.description.length > 1000) {
    newErrors.description = 'Description must not exceed 1000 characters';
  }

  if (offerData.offer?.additionalInfo && offerData.offer.additionalInfo.length > 500) {
    newErrors.additionalInfo = 'Additional info must not exceed 500 characters';
  }

  if (!useBusinessAddress && !newAddress) {
    newErrors.location = 'Address must be selected';
  }

  if(!newAddress || !isValidGooglePlace) {
    newErrors.location = 'A valid address must be selected from the address dropdown';
  };

  if (!startTime || !endTime) {
    newErrors.time = 'Both start and end times are required';
  }

  // Check start and end dates
  if (fromDate && toDate) {
    if (fromDate > toDate) {
      newErrors.dates = 'End date must be after start date';
    }
  } else if (!fromDate || !toDate) {
    newErrors.dates = 'Both start and end dates are required';
  }

  // Tax Code validation
  if (!offerData.offer?.taxCode) {
    newErrors.taxCode = 'Please select a tax code';
  }

  // Location validation
  if (!useBusinessAddress && (!offerData.offer?.locations || offerData.offer.locations.length === 0 || !offerData.offer.locations[0].address)) {
    newErrors.location = 'Please set a location for your offer';
  }

  // Promotion validations
  if (selectedDealType) {
    if (promotionData.type === 'PERCENTAGE_DISCOUNT') {
      if (promotionData.discountValue === undefined || promotionData.discountValue <= 0 || promotionData.discountValue > 100) {
        newErrors.discountValue = 'Discount value must be between 1 and 100';
      }
      if (promotionData.discountValue && offerData.offer?.price > 0 && ((100-promotionData.discountValue) * offerData.offer?.price* 0.01) < 10) {
        newErrors.discountValue = 'Price after discount must be at least $10';
      }
    } else if (promotionData.type === 'BUY_X_GET_Y_FREE') {
      if (promotionData.minimumPurchaseAmount === undefined || promotionData.minimumPurchaseAmount <= 0) {
        newErrors.minimumPurchaseAmount = 'Minimum purchase amount must be a positive number';
      }
      if (promotionData.discountValue === undefined || promotionData.discountValue <= 0) {
        newErrors.discountValue = 'Free item quantity must be a positive number';
      }
    }

    if (promotionData.maxUsageCount === undefined || promotionData.maxUsageCount <= 0) {
      newErrors.maxUsageCount = 'Please re-enter maximum usage amount.';
    }

    if (!fromDate || !toDate) {
      newErrors.promotionDates = 'Both start and end dates are required for the promotion';
    } else if (fromDate >= toDate) {
      newErrors.promotionDates = 'End date must be after start date';
    }
  }

  setErrors(newErrors);
  if(newErrors)
    console.error(newErrors);
  return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="flex flex-col">
      <MerchantHubHeader />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">
        <SideNavPost selected="Post pricing details" />
        <div className="flex flex-row p-8 mq780:w-[85vw]">
        <form onSubmit={handleNext} className="flex flex-col font-subhead w-full mb-20">
            <div className="flex flex-col pt-0 pb-3 w-full font-subhead">
              <div className="flex flex-row justify-start mq780:hidden">
                <ChevronLeft onClick={onGoBackClick} className="cursor-pointer w-6 h-6 pt-2.5 pr-3 stroke-black" />
                <p className="bg-transparent mt-3 font-semibold">My Deals</p>
              </div>
            </div>
            <div className="self-stretch m-0 mt-0 text-19xl mq780:text-29xl font-semibold tracking-tight leading-[50.04px] text-zinc-700 max-md:max-w-full">
              Post Details
            </div>
            <div className="flex flex-row gap-2">
              <div className="self-stretch my-3 text-4xl font-semibold tracking-tight leading-9 text-zinc-700 max-md:max-w-full">
                Create your deal
              </div>
              {offerData && <>
              <div className="hidden self-stretch my-3 text-4xl font-semibold tracking-tight leading-9 text-zinc-700 max-md:max-w-full">
                •
              </div>
              <div  onClick={() => {removeCachedData();window.location.reload();}}  className="hidden cursor-pointer self-stretch my-3 text-4xl font-semibold tracking-tight leading-9 text-coral underline max-md:max-w-full">
                Clear Deal
              </div>
              </>}
            </div>
            <div className="mt-3 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full max-w-[600px]">
              Title of Post
            </div>
            <input
              type="text"
              name="offer.name"
              placeholder="e.g. 50% on all Pizzas"
              value={offerData?.offer?.name || ''}
              onChange={handleInputChange}
              className="justify-center items-start px-3.5 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-zinc-800 max-w-[500px] mr-8"
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
            <section className="flex flex-col">
              <div className="mt-8 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full max-w-[600px]">
                Set Location
              </div>
              <div className="flex flex-col gap-6 pb-4 max-w-[530px]">
                <label className="hidden flex items-center">
                  <input
                    type="checkbox"
                    checked={useBusinessAddress}
                    onChange={handleUseBusinessAddressChange}
                    className="form-checkbox w-5 h-5"
                  />
                  <span className="ml-2">Use my business address</span>
                </label>
                <label className="hidden flex items-center">
                  <input
                    type="checkbox"
                    checked={!useBusinessAddress}
                    onChange={handleSetNewAddressChange}
                    className="form-checkbox w-5 h-5"
                  />
                  <span className="ml-2">Set a new address</span>
                </label>
                {!useBusinessAddress && false && (
                  <div className="flex flex-col pt-0 pb-0">
                    <p className="font-subhead text-zinc-900 m-0 mt-2 mb-1">
                      Enter location Address:
                    </p>
                    <input
                      type="text"
                      value={newAddress}
                      onChange={(e) => {
                        setNewAddress(e.target.value);
                        handleNewAddressInput(e.target.value);
                      }}
                      className="justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                      placeholder="Enter your business address"
                    />
                    {errors.location && <p className="text-red-500 text-sm mt-1">{errors.location}</p>}
                  </div>
                )}
                  <GooglePlacesAutocomplete
                    ref={addressInputRef}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={handlePlaceSelected}
                    options={{
                      types: ['address'],
                    }}
                    defaultValue={newAddress}
                    className="justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                    placeholder="Enter your business address"
                    onBlur={handleInputBlur}
                    onChange={handleAddressChange}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.location && <p className="text-red-500 text-sm mt-1">{errors.location}</p>}
              </div>
            </section>
            <div className="mt-10 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full">
              Regular price ($)
            </div>
            <input
              type="text"
              name="offer.price"
              placeholder="$ 0.00"
              value={offerData.offer?.price || ''}
              onChange={handleInputChange}
              onBlur={handlePriceBlur}
              className="justify-center items-start px-3.5 py-1.5 mt-2 w-[20vw] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-zinc-800 mb-0"
            />
            {errors.price && <p className="text-red-500 text-sm mt-1">{errors.price}</p>}
            <div className="flex flex-col font-semibold">
              <div className="mt-10 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full">
                Max Usage Count
              </div>
              <input
                type="text"
                placeholder="e.g.: 50"
                className="w-[20vw] justify-center items-start px-3.5 py-1.5 mt-2 text-base leading-7 rounded-lg border border-solid border-zinc-700 text-zinc-800"
                value={voucherCap}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^$|^\d*$/.test(value)) {
                    setVoucherCap(value);
                    setPromotionData(prevData => ({
                      ...prevData,
                      maxUsageCount: value === '' ? undefined : parseInt(value, 10)
                    }));
                  }
                }}
              />
            </div>
            {errors.maxUsageCount && <p className="text-red-500 text-sm mt-1">{errors.maxUsageCount}</p>}
            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <div className="-mt-4 flex flex-col text-base h-auto font-bold leading-7 text-zinc-700 max-md:max-w-inherit">
              <div className="mt-2 flex flex-col text-base font-bold leading-7 text-zinc-700 w-inherit">
                <p className="text-inherit">Select Event Times</p>
                  <div className="flex flex-row justify-start gap-8 w-inherit">
                    <button
                      type="button"
                      onClick={() => toggleButton('ONCE')}
                      className={`flex items-center justify-center p-14 h-28 bg-white max-w-[37vw] hover:border-blue-400 cursor-pointer border font-subhead border-1 rounded-lg focus:border-blue-400 ${
                        clickedButtons.oneTime ? 'text-blue-500 border-blue-400' : 'text-grey-500 border-zinc-900'
                      }`}
                    >
                      One Time Event
                    </button>
                    <button
                      type="button"
                      onClick={() => toggleButton('RECURRING')}
                      className={`flex items-center justify-center p-14 h-28 bg-white max-w-[37vw] hover:border-blue-400 cursor-pointer border font-subhead border-1 rounded-lg focus:border-blue-400 ${
                        clickedButtons.recurring ? 'text-blue-500 border-blue-400' : 'text-grey-500 border-zinc-900'
                      }`}
                    >
                      Recurring Event
                    </button>
                  </div>
              </div>
            </div>

            {clickedButtons.recurring &&
              <div className="flex flex-col mt-8">
              <label htmlFor="frequency" className="mb-2 font-medium">Frequency</label>
              <select
                id="frequency"
                value={frequency}
                onChange={handleFrequencyChange}
                className="font-subhead px-2 w-[51%] py-1.5 rounded-lg border border-solid border-zinc-700"
              >
                {/* <option value="ONCE">Once</option> */}
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
              </select>
              </div>
            }
            <div className="flex flex-row gap-10 dw-inherit mt-10">
              <div className="flex flex-col">
                <label htmlFor="startTime" className="mb-2 font-medium">Start Time</label>
                <input
                  type="time"
                  id="startTime"
                  value={formattedDate(startTime)}
                  onChange={handleStartTimeChange}
                  className="font-subhead px-3.5 py-1.5 rounded-lg border border-solid border-zinc-700"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="endTime" className="mb-2 font-medium">End Time</label>
                <input
                  type="time"
                  id="endTime"
                  value={formattedDate(endTime)}
                  onChange={handleEndTimeChange}
                  className="font-subhead px-3.5 py-1.5 rounded-lg border border-solid border-zinc-700"
                />
              </div>
            </div>
            {errors.time && <p className="text-red-500 text-sm mt-1">{errors.time}</p>}

            <p className="text-lg mb-5 mt-10 font-semibold">Set the launch date</p>
            <p className="hidden m-0 text-start pb-5 text-neutral-500 font-subhead w-inherit">
              Notice: It might take DoYouWeekend a few days to approve your promotion.
            </p>

            <div onClick={handleCalendarFromClick} className="flex items-center bg-white rounded-md p-2 mb-4 border border-solid border-blue-900 border border-solid border-zinc-700 min-w-[100px] max-w-[300px]">
              <CalendarClock className="text-main-teal mr-2" size={30} />
              <input
                value={fromDate ? fromDate.toLocaleDateString() : ""}
                placeholder="Select start date"
                readOnly
                className="bg-transparent w-full text-zinc-900 text-lg border-none outline-none text-copy mt-[2px]"
              />
            </div>
            {calendarFromShown && 
              <Calendar
                title={`${new Date(year, fromMonth).toLocaleString('default', { month: 'long' })}`}
                selectedDate={fromDate}
                setSelectedDate={handleFromDateSelect}
                year={year}
                month={fromMonth}
                onMonthChange={handleFromMonthChange}
                onYearChange={(newYear) => setYear(newYear)}
                minDate={new Date()} // Set minimum date to today
              />
            }
              <div onClick={handleCalendarToClick} className="flex items-center my-4 bg-white rounded-md p-2  border border-solid border-blue-900 border border-solid border-zinc-700 min-w-[100px] max-w-[300px]">
                <CalendarCheck className="text-main-teal mr-2" size={30} />
                <input
                  value={toDate ? toDate.toLocaleDateString() : ""}
                  placeholder="Select end date"
                  readOnly
                  className="bg-transparent w-full text-zinc-900 text-lg border-none outline-none text-copy mt-[2px]"
                />
              </div>
              {calendarToShown && 
                <Calendar
                  title={`${new Date(year, toMonth).toLocaleString('default', { month: 'long' })}`}
                  selectedDate={toDate}
                  setSelectedDate={handleToDateSelect}
                  year={year}
                  month={toMonth}
                  onMonthChange={handleToMonthChange}
                  onYearChange={(newYear) => setYear(newYear)}
                  isEndDatePicker={true}
                  startDate={fromDate}
                />
              }
            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <p className="text-lg mb-5 font-semibold">Select Promotion type</p>
            <div className="mt-2 flex flex-col text-base font-bold leading-7 text-zinc-700 w-inherit">
            <div className="flex flex-row justify-start gap-8 w-inherit">
              <button
                type="button"
                onClick={() => clickToggleDealType('PERCENTAGE_DISCOUNT')}
                className={`flex items-center justify-center p-14 h-28 bg-white max-w-[37vw] hover:border-blue-400 cursor-pointer border font-subhead border-1 rounded-lg focus:border-blue-400 ${
                  selectedDealType === 'PERCENTAGE_DISCOUNT' ? 'text-blue-500 border-blue-400' : 'text-grey-500 border-zinc-900'
                }`}
              >
                Percentage Discount
              </button>
              <button
                type="button"
                onClick={() => clickToggleDealType('BUY_X_GET_Y_FREE')}
                className={`flex items-center justify-center p-14 h-28 bg-white max-w-[37vw] hover:border-blue-400 cursor-pointer border font-subhead border-1 rounded-lg focus:border-blue-400 ${
                  selectedDealType === 'BUY_X_GET_Y_FREE' ? 'text-blue-500 border-blue-400' : 'text-grey-500 border-zinc-900'
                }`}
              >
                Buy one get one
              </button>
            </div>
            </div>
            {selectedDealType === 'PERCENTAGE_DISCOUNT' && 
            <>
              <div className="mt-10 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full">
                Suggest discount voucher cap
              </div>
              <div className="w-inherit pt-12 w-[90%]">
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={Math.floor(promotionData?.discountValue || 50)}
                  onChange={(value) => {
                    setPromotionData(prevData => {
                      const newData = { ...prevData, discountValue: value };
                      // Recalculate deal price immediately
                      const price = offerData.offer?.price || 0;
                      const calculatedDealPrice = price * (1 - value / 100);
                      setDealPrice(calculatedDealPrice);
                      setManualDealPrice(calculatedDealPrice.toFixed(2));
                      return newData;
                    });
                  }}
                />
              </div>
            <p className="pt-10 text-neutral-500 font-subhead w-inherit">
              Your voucher matters a lot in attracting customers, try to find the right balance between an attractive discount but still getting paid what your service is worth!
            </p>
            <div className="flex flex-row gap-10 w-inherit">
              <div className="flex flex-col font-semibold">
                <p className="text-inherit m-0 mb-3 mt-6">Deal price ($)</p>
                <input
                  type="text"
                  value={manualDealPrice}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      setManualDealPrice(value);
                      const dealPrice = parseFloat(value);
                      const originalPrice = offerData.offer?.price || 0;
                      if (dealPrice && originalPrice) {
                        const newDiscountValue = calculateDiscountValue(originalPrice, dealPrice);
                        setPromotionData(prevData => ({
                          ...prevData,
                          discountValue: newDiscountValue,
                        }));
                        setDealPrice(dealPrice);
                      }
                    }
                  }}
                  onBlur={() => {
                    if (manualDealPrice === '') {
                      setManualDealPrice(dealPrice.toFixed(2));
                    }
                  }}
                  className="w-[20vw] justify-center items-start px-3.5 py-1.5 mt-2 text-base leading-7 rounded-lg border border-solid border-zinc-700 text-zinc-800"
                />
              </div>
            </div>
            {errors.discountValue && <p className="text-red-500 text-sm mt-1">{errors.discountValue}</p>}
            </>}  

            {selectedDealType === 'BUY_X_GET_Y_FREE' &&
            <>
              <div className="flex mt-3 items-center space-x-2 text-5xl font-semibold max-w-inherit">
              <span className="pl-0 ml-0">Buy</span>
              <input
                type="text"
                value={limit}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^$|^\d*$/.test(value)) {
                    setLimit(value);
                    setPromotionData(prevData => ({
                      ...prevData,
                      minimumPurchaseAmount: parseInt(value) || 1
                    }));
                  }
                }}
                className="w-auto max-w-10 text-5xl font-semibold mt-[2px] text-blue-500 text-center font-subhead [text-decoration:underline] border-none outline-none"
              />
              <p>get</p>
              <input
                type="text"
                value={giftLimit}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^$|^\d*$/.test(value)) {
                    setGiftLimit(value);
                    setPromotionData(prevData => ({
                      ...prevData,
                      discountValue: parseInt(value) || 1
                    }));
                  }
                }}
                className="w-auto max-w-8 text-5xl font-semibold mt-[2px] text-blue-500 text-center font-subhead [text-decoration:underline] border-none outline-none grow"
              />
            <span className=''>Free</span>
            </div>
            <p className="pb-0 text-neutral-500 font-subhead w-inherit">
              Your voucher matters a lot in attracting customers, try to find the right balance between an attractive discount but still getting paid what your service is worth!
            </p>
            <div className="flex flex-row gap-10 w-inherit">
              <div className="hidden flex flex-col font-semibold">
                <p className="text-inherit m-0 mb-3 mt-6">Voucher Cap</p>
                <input
                  type="text"
                  placeholder="e.g.: 50"
                  className="w-[20vw] justify-center items-start px-3.5 py-1.5 mt-2 text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                  value={voucherCap}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^$|^\d*$/.test(value)) {
                      setVoucherCap(value);
                    }
                  }}
                />
              </div>
              <div className="flex flex-col font-semibold mt-0">
              <p className="hidden text-inherit m-0 mb-3 mt-6">Max total income</p>
              <input
                readOnly
                type="text"
                value={`$ ${totalIncome.toFixed(2)}`}
                className="hidden w-[20vw] justify-center items-start px-3.5 py-1.5 mt-2 text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
              />
            </div>
            </div>
            <div className="flex flex-col font-semibold mt-8">
              <p className="hidden text-inherit m-0 mb-3 mt-6">Max total income</p>
              <input
                readOnly
                type="text"
                value={`$ ${totalIncome.toFixed(2)}`}
                className="hidden w-[20vw] justify-center items-start px-3.5 py-1.5 mt-2 text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
              />
            </div>
            {errors.minimumPurchaseAmount && <p className="text-red-500 text-sm mt-1">{errors.minimumPurchaseAmount}</p>}
            {errors.discountValue && <p className="text-red-500 text-sm mt-1">{errors.discountValue}</p>}
            </>}  


            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <p className="text-lg mb-0 font-semibold">Offer Categories</p>
            <div className="mt-2 w-full text-base leading-7 text-zinc-800">Choose <strong>up to 3 categories</strong> that best fit your offer.</div>
            <div
              className="flex items-center justify-center cursor-pointer mt-2 w-inherit rounded-lg border border-solid border-zinc-700 h-[30px]"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
            {isDropdownOpen && (
              <div className="mt-2 border border-solid border-zinc-700 rounded-lg p-4">
                <div className="flex flex-col">
                {categories.map(category => (
                  <label key={category.id} className='py-[3px] text-lg cursor-pointer'>
                    <input
                      className='mr-2 cursor-pointer'
                      type="checkbox"
                      checked={offerData.offer?.categoryIds?.includes(category.id) || false}
                      onChange={() => handleCategoryChange(category.id)}
                    />
                    {category.name}
                  </label>
                ))}
                </div>
              </div>
            )}
            {errors.categories && <p className="text-red-500 text-sm mt-1">{errors.categories}</p>}
            <div className="hidden h-[2px] my-16 bg-zinc-300 w-inherit" />
            <section className="hidden flex flex-col">
              <p className="text-lg m-0 mb-8 font-semibold">Terms & limits</p>
              <p className="m-0 text-start pb-5 text-neutral-500 font-subhead w-inherit">
                  We emphasize on being business friendly so you can sete your deal exactly on your terms.
              </p>
              <div className="flex flex-col gap-6 py-4 w-inherit">
                <LimitSelector1 />
                <LimitSelector2 />
                <CheckBoxText text="Amount paid never expires" />
              </div>
            </section>
            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <section className="flex flex-col">
              <p className="text-lg m-0 mb-2 font-semibold">Description</p>
              <p className="m-0 text-start pb-5 text-neutral-500 font-subhead w-inherit">
                  This description is very important for recommendations, try to describe your offer as well as possible!
              </p>
              <div className="items-start px-5 py-5 mt-0 w-inherit text-base leading-7 rounded-md border border-solid border-zinc-800 text-neutral-500 max-md:pr-5 max-md:max-w-full relative">
                <textarea
                  name="offer.description"
                  placeholder="Enter your 1-3 sentence description here"
                  value={offerData.offer?.description || ''}
                  onChange={handleInputChange}
                  className="h-32 transparent-input font-subhead text-neutral-500 text-start text-base w-full focus:ring-0 resize-none"
                  maxLength={1000}
                />
                <div className="absolute bottom-2 right-2 text-sm text-gray-500">
                  {descriptionCharCount}/1000
                </div>
                {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
              </div>
            </section>
            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <section className="hidden flex flex-col">
              <p className="text-lg m-0 mb-0 font-semibold">Set Location</p>
              <div className="flex flex-col gap-6 py-4">
                <label className="hidden flex items-center">
                  <input
                    type="checkbox"
                    checked={useBusinessAddress}
                    onChange={handleUseBusinessAddressChange}
                    className="form-checkbox w-5 h-5"
                  />
                  <span className="ml-2">Use my business address</span>
                </label>
                <label className="hidden flex items-center">
                  <input
                    type="checkbox"
                    checked={!useBusinessAddress}
                    onChange={handleSetNewAddressChange}
                    className="form-checkbox w-5 h-5"
                  />
                  <span className="ml-2">Set a new address</span>
                </label>
                {!useBusinessAddress && false && (
                  <div className="flex flex-col pt-0 pb-0">
                    <p className="font-subhead text-zinc-900 m-0 mt-2 mb-1">
                      Enter location Address:
                    </p>
                    <input
                      type="text"
                      value={newAddress}
                      onChange={(e) => {
                        setNewAddress(e.target.value);
                        handleNewAddressInput(e.target.value);
                      }}
                      className="justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                      placeholder="Enter your business address"
                    />
                    {errors.location && <p className="text-red-500 text-sm mt-1">{errors.location}</p>}
                  </div>
                )}
                {/* <GooglePlacesAutocomplete
                  ref={addressInputRef}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handlePlaceSelected}
                  options={{
                    types: ['address'],
                  }}
                  defaultValue={newAddress}
                  className="justify-center items-start px-4 py-1.5 mt-2 w-inherit text-base leading-7 rounded-lg border border-solid border-zinc-700 text-neutral-500"
                  placeholder="Enter your business address"
                /> */}
                  {errors.location && <p className="text-red-500 text-sm mt-1">{errors.location}</p>}
              </div>
            </section>
            <div className="hidden h-[2px] my-16 bg-zinc-300 w-inherit" />
            <section className="flex flex-col">
              <p className="text-lg m-0 mb-2 font-semibold">Know before you go</p>
              <p className="m-0 text-start pb-5 text-neutral-500 font-subhead w-inherit">
                Please add any extra information the user should know!
              </p>
              <div className="items-start px-5 py-5 mt-0 w-inherit text-base leading-7 rounded-md border border-solid border-zinc-800 text-neutral-500 max-md:pr-5 max-md:max-w-full relative">
                <textarea
                  name="offer.additionalInfo"
                  placeholder="(Optional) add text here"
                  value={offerData.offer?.additionalInfo || ''}
                  onChange={handleInputChange}
                  className="h-32 transparent-input font-subhead text-neutral-500 text-start text-base w-full focus:ring-0 resize-none"
                  maxLength={500}
                />
                <div className="absolute bottom-2 right-2 text-sm text-gray-500">
                  {additionalInfoCharCount}/500
                </div>
              </div>
            </section>
            <div className="h-[2px] my-16 bg-zinc-300 w-inherit" />
            <div className="flex flex-col">
              <p className="text-lg m-0 mb-2 font-semibold">Add Highlights</p>
              <p className="m-0 text-start pb-5 text-neutral-500 font-subhead w-inherit">
                  Inform the user about any extra services you provide
              </p>
              <div className="flex flex-col gap-6 py-4">
                <AccessibilityButtons
                  selectedHighlightIds={selectedHighlightIds}
                  onHighlightsChange={handleHighlightsChange}
                />
                <div className="h-[2px] mt-8 bg-zinc-300 w-inherit" />
                <div className="mt-10 text-base font-medium leading-7 text-zinc-700 max-md:max-w-full">
                  Tax Code
                </div>
                <select
                  name="offer.taxCode"
                  value={offerData.offer?.taxCode || ''}
                  onChange={handleTaxCodeChange}
                  className="justify-center items-start px-3.5 py-2.5 mt-0 w-[max(225px,22vw)] text-base leading-7 rounded-lg border border-solid border-zinc-700 text-zinc-800 mb-0"
                >
                  <option value="">Select a tax code</option>
                  {taxCodes.map((taxCode) => (
                    <option key={taxCode.id} value={taxCode.id}>
                      {taxCode.name}
                    </option>
                  ))}
                </select>
                {errors.taxCode && <p className="text-red-500 text-sm mt-1">{errors.taxCode}</p>}
                <div className="flex justify-end mt-10 w-inherit">
                  <button
                    type="submit"
                    className="cursor-pointer w-[110px] p-2 text-base font-medium tracking-tight hover:bg-blue-800 text-center text-white bg-blue-900 rounded-lg"
                  >
                    Next
                  </button>
                </div>
                {/* Error display section */}
                <div className="mt-4">
                  {apiError && (
                    <p className="text-red-500 text-sm">{apiError}</p>
                  )}
                  {Object.values(errors).map((error, index) => (
                    <p key={index} className="text-red-500 text-sm">{error}</p>
                  ))}
                </div>
              </div>
            </div>
          </form>
          <div className="hidden mq1050:flex flex-col grow h-auto">
            <CompanyCard />
            <div className=""/>
          </div>
        </div>
      </main>
      <footer className="mt-auto">
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>
  );
};

export default MerchantHubPost1;