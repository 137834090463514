import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { Download, AlignJustify, ChevronDown, ChevronUp } from "lucide-react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface NavigationItemProps {
  text: string;
  onClick: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ text, onClick }) => {
    return (
      <button
        className="mx-2 font-subhead hidden lg:flex text-base cursor-pointer bg-transparent tracking-tight text-light-grey hover:text-steelblue focus:text-blue-900"
        onClick={onClick}
      >
        {text}
      </button>
    );
  };

interface ButtonProps {
  text: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <button
      className="hidden pb-2 mq780:flex cursor-pointer flex-col justify-center rounded-lg border bg-blue-900 border-blue-900 border-solid hover:border-steelblue"
      onClick={onClick}
    >
      <div className="hidden pb-0 mq780:flex justify-center p-2 bg-blue-900 rounded-lg text-base text-white font-bold">
        {text}
      </div>
    </button>
  );
};

interface MerchantHubHeaderProps {
  selected?: 'Home' | 'Analytics' | 'Deals' | 'FAQ';
}

function MerchantHubHeader({ selected }: MerchantHubHeaderProps) {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [analyticsClickable, setAnalyticsClickable] = useState(true);
  const [showError, setShowError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const jwt = localStorage.getItem('jwtToken');
    const isMerchant = localStorage.getItem('userType') === "MERCHANT";
    setIsLoggedIn(!!jwt && isMerchant);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userType");
    setIsLoggedIn(false);
    navigate("/merchant-hub");
  };

  const handleStripeAction = async (actionType: 'onboarding' | 'update' | 'dashboard') => {
    if (actionType === 'dashboard' && !analyticsClickable) {
      return;
    }

    try {
      let response;
      if (actionType === 'dashboard') {
        setAnalyticsClickable(false);
        setTimeout(() => setAnalyticsClickable(true), 5000);

        response = await axios.post(
          `${API_BASE_URL}/v1/merchants/dashboard-link`, 
          {}, // Empty object as body
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );
      } else {
        // ... (existing code for other action types)
      }
  
      if (response && response.status === 200 && response.data) {
        window.open(response.data, '_blank');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error(`Error fetching Stripe ${actionType} link:`, error);
      if (actionType === 'dashboard') {
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
        navigate('/merchant-hub-home');
      }
    }
  };


  const businessName = ""
  const handleSupportClick = () => {
    // // console.log("Support");
    window.location.href = "mailto:andrew@doyouweekend.com?subject=SUPPORT%20REQUEST%20from%20"+businessName;
  };

  const navigationItemsDropdown = [
    {
      text: "Home",
      onClick: () => {       
        if (localStorage.getItem('jwtToken')) {
          navigate('/merchant-hub-home');
        } else {
          navigate('/merchant-hub');
        }; 
      }
    },
    { 
      text: "FAQ", 
      onClick: () => { navigate('/merchant-hub-faq'); }
    },
    { 
      text: "Business Info", 
      onClick: () => { navigate('/merchant-business-info'); }
    },
    { 
      text: "Contact Support", 
      onClick: handleSupportClick 
    },
    isLoggedIn
      ? { 
          text: "Log out", 
          onClick: handleLogout 
        }
      : { 
          text: "Log in", 
          onClick: () => { navigate('/merchant-login'); }
        }
  ];

  const navigationItems = [
    {
      text: "Home",
      onClick: () => {       
        if (localStorage.getItem('jwtToken')) {
          navigate('/merchant-hub-home');
        } else {
          navigate('/merchant-hub');
        }; 
      }
    },
    { 
      text: "FAQ", 
      onClick: () => { navigate('/merchant-hub-faq'); }
    },
    { 
      text: "Contact Support", 
      onClick: handleSupportClick 
    }
  ];

  const { pathname } = useLocation();

  const onHomeClick = () => {  
      if (pathname == "/merchant-hub") {
        navigate("/");
      }
      else if (localStorage.getItem('jwtToken')) {
        navigate('/merchant-hub-home');
      } else {
        navigate('/merchant-hub');
      }; 
  };

  const handleMerchantClick = () => {
    navigate('/merchant-signup')
  };

  const onLoginClick = () => {
    navigate('/merchant-login');
  };

  const onMenuClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const mainPages = [
    "/merchant-hub-home",
    "/merchant-hub-account",
    "/merchant-hub-deals",
    "/merchant-hub-analytics",
    "/merchant-business-info",
    "/merchant-hub-faq",
  ];

  const isShiftPage = (pathname: string) => {
    const staticPages = [
      "/merchant-hub",
      "/merchant-signup",
      "/merchant-hub-post-1",
      "/merchant-hub-post-2",
      "/merchant-hub-post-3",
    ];
  
    if (staticPages.includes(pathname)) {
      return true;
    }
  
    // Check for /merchant-hub-post-1/:offerId
    const offerMatch = useMatch("/merchant-hub-post-1/:offerId");
    if (offerMatch) {
      return true;
    }
  
    return false;
  };

  const showNavBar = mainPages.includes(location.pathname);
  const shiftBar = isShiftPage(location.pathname);

  return ( 
    <div className="overflow-x-hidden w-full">
      {/* Desktop Header */}
      <div className="hidden mq780:flex flex-col">
      <div className="justify-between pt-1.5 flex flex-col self-stretch box-border pb-1.5 w-full text-base font-medium bg-white text-zinc-700">
        <div className="flex w-full justify-between"> 
          <div className="flex items-center mt-1 align-text-bottom pr-0 mr-0">
            <img
              loading="lazy"
              src="/new-logo@2x.png"
              alt="Logo"
              className="self-start ml-5 aspect-[5] w-[190px] pb-2 cursor-pointer"
              onClick={onHomeClick}
              crossOrigin="anonymous"
            />
            <p onClick={onHomeClick} className="cursor-pointer whitespace-nowrap flex font-semibold text-xl mb-1.5 mt-2.5 font-subhead hidden mq480:block">Merchant Hub</p>
            <div className="hidden mq480:flex pl-20 gap-8 mt-0">
              {navigationItems.map((item, index) => (
                <NavigationItem
                  key={index}
                  text={item.text}
                  onClick={item.onClick}
                />
              ))}
            </div>
          </div>
          <div className="flex gap-8 mt-1 tracking-tight text-white pr-7">
            {isLoggedIn ? (
              <button 
                onClick={handleLogout} 
                className="cursor-pointer hidden mt-0 mq780:flex bg-main-teal text-white text-base font-subhead font-semibold px-4 py-3 rounded-lg"
              >
                Log out
              </button>
            ) : (
              <>
                <button onClick={onLoginClick} className="cursor-pointer hidden mt-3 mq780:flex bg-transparent text-base font-subhead font-semibold text-blue-900">Log in</button>
                <Button text="Sign up" onClick={handleMerchantClick} />
              </>
            )}
            <AlignJustify className="cursor-pointer block lg:hidden w-9 h-9 stroke-zinc-700 pt-1.5" onClick={onMenuClick} />
          </div>
        </div>
      </div>
      <div className="mt-0 w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" /> 
      
      {isDropdownVisible && (
        <div className="absolute right-0 mt-[60px] w-[200px] mr-5 bg-white rounded-lg !shadow-lg p-2 z-50">
          <div className="flex flex-col font-subhead gap-3">
            {navigationItemsDropdown.map((item, index) => (
              <button
                key={index}
                className="text-left text-zinc-700 bg-white font-subhead hover:font-semibold p-2 rounded-lg"
                onClick={item.onClick}
              >
                {item.text}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>

    {/* Mobile Header */}
    <div className="mq780:hidden w-full">
      <div className="justify-between pt-1.5 flex flex-col self-stretch box-border pb-1.5 w-full text-base font-medium bg-white text-zinc-700 shadow-lg">
        <div className="flex flex-row w-full justify-between items-center align-text-bottom pr-0 mr-0">
          <div className="relative"> {/* Add this wrapper div */}
            <AlignJustify className="mq780:hidden cursor-pointer block lg:hidden w-9 h-9 min-h-9 min-w-9 stroke-zinc-700 pt-1.5 ml-6" onClick={onMenuClick} />
            {isDropdownVisible && (
              <div className="absolute left-0 top-full mt-2 w-[200px] ml-2 bg-white rounded-lg !shadow-lg p-2 z-50">
                <div className="flex flex-col font-subhead gap-3">
                  {navigationItemsDropdown.map((item, index) => (
                    <button
                      key={index}
                      className="text-left text-zinc-700 bg-white font-subhead hover:font-semibold p-2 rounded-lg"
                      onClick={item.onClick}
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <img
              loading="lazy"
              src="/new-logo@2x.png"
              alt="Logo"
              className="self-start aspect-[5] w-[190px] mb-0 cursor-pointer"
              onClick={onHomeClick}
              crossOrigin="anonymous"
            />
            <p onClick={onHomeClick} className="ml-[20px] pl-2 cursor-pointer whitespace-nowrap flex font-semilight text-lg mb-1.5 mt-0 font-subhead">Merchant Hub</p>
          </div>
          <img onClick={() => navigate("/merchant-hub-account")} src="/Avatar_Default.png" className="mq780:hidden w-[35px] h-[35px] mt-1 mr-6" crossOrigin="anonymous" />
        </div>
      </div>
      {showNavBar && (
      <div className="mq780:hidden h-[45px] bg-dark-navy flex flex-row justify-between px-2 mq330:px-4 mq400:px-6 mq480:px-8">
        <a 
          href="/merchant-hub-home" 
          className={`flex-1 text-white no-underline text-xxs mq330:text-xs mq400:text-sm mq480:text-base text-center py-3 font-subhead
            ${selected === 'Home' ? 'font-bold bg-[#2f323f]' : ''}`}
        >
          Home
        </a>
        <a 
          href="/merchant-hub-deals" 
          className={`flex-1 text-white no-underline text-xxs mq330:text-xs mq400:text-sm mq480:text-base text-center py-3 font-subhead
            ${selected === 'Deals' ? 'font-bold bg-[#2f323f]' : ''}`}
        >
          Deals
        </a>
        <a 
          onClick={() => handleStripeAction('dashboard')}
          className={`flex-1 text-white no-underline text-xxs mq330:text-xs mq400:text-sm mq480:text-base text-center py-3 font-subhead cursor-pointer
            ${selected === 'Analytics' ? 'font-bold bg-[#2f323f]' : ''}
            ${!analyticsClickable ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Analytics
        </a>
        <a 
          href="/merchant-hub-faq" 
          className={`flex-1 text-white no-underline text-xxs mq330:text-xs mq400:text-sm mq480:text-base text-center py-3 font-subhead
            ${selected === 'FAQ' ? 'font-bold bg-[#2f323f]' : ''}`}
        >
          FAQ
        </a>
      </div>
    )}
      <div className="mt-0 w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" /> 
    </div>
    </div>
  );
}

export default MerchantHubHeader;
