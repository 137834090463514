import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

export type MiniRounderType = {
  coverImage: string;
  activityTitle: string;
  activityLink?: string;
  categories?: string[];
  distance: string;
  numReviews?: string;
  priceOld?: number;
  priceNew?: number;
  /** Action props */
  
  onActivityClick?: () => void;
};

const MiniRounder: FunctionComponent<MiniRounderType> = ({
  coverImage,
  activityTitle,
  activityLink,
  categories,
  onActivityClick,
  distance,
  numReviews,
  priceOld,
  priceNew,

}) => {

  const navigate = useNavigate();

  const handleActivityClick = () => {
    if (activityLink) {
      navigate(activityLink);
    } else if (onActivityClick) {
      onActivityClick(); 
    }
  };

  const [isLiked, setIsLiked] = useState(false);

  const handleLikeClick = () => {
    setIsLiked(!isLiked); // Toggle the isLiked state
    //// console.log("Liked!")
    //Add API functionality to save Likes
  };

  function CategoriesDisplay(categories: string[] | undefined) {
    return (
      <div>
        {categories && categories.length > 0 && categories.join(" • ")}
      </div>
    );
  }
  
  return (
    <div className="py-2 self-stretch w-[285px] shrink-0 flex flex-col items-start justify-start box-border text-left text-base text-copy font-subhead relative">
      <div className="self-stretch flex-1 flex flex-row gap-3 items-start justify-start z-[1] relative">
        <img
          className="self-stretch h-[120px] w-[120px] pt-2 relative max-w-full overflow-hidden shrink-0 object-cover"
          alt=""
          src={coverImage}
        />
        <img
          className="hidden absolute top-0 right-0 mt-1.5 mx-1 h-7 w-7 cursor-pointer"
          alt=""
          src={isLiked ? "/icon--heart1.svg" : "/icon--heart.svg"}
          onClick={handleLikeClick}
        />
        <div className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[8px]">
          <div
            className="relative text-lg tracking-[-0.01em] font-semibold text-main-teal cursor-pointer mq450:text-base pr-4"
            onClick={handleActivityClick}
          >
            {activityTitle}
          </div>
          {(priceNew && priceNew > 0 || priceOld && priceOld > 0) && 
          <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-5xl text-main-teal">
            <div className="hidden pr-2 relative [text-decoration:line-through] tracking-[-0.01em] inline-block min-w-[65px] whitespace-nowrap text-xl mq450:text-base">
              ${priceOld}
            </div>
            <b className="flex-1 relative tracking-[-0.01em] inline-block text-green min-w-[94px] whitespace-nowrap text-xl mq450:text-base">
              ${priceNew?.toFixed(2)}
            </b>
          </div>}
          <div className="text-mini self-stretch relative font-medium whitespace-pre-wrap">
            {CategoriesDisplay(categories)}
          </div>
          {localStorage.getItem("userLocation") && distance &&
          <div className="self-stretch relative [text-decoration:underline] font-medium">
            {distance} miles away {/* Still displays "N/A" miles away */}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default MiniRounder;