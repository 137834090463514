import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import Header from "../../components/User/Header";
import UserFooter from "../../components/User/UserFooter";
import CheckoutItem from "../../components/User/CheckoutItem";
import StripeProvider from "../../components/Stripe/StripeProvider";
import axios from "axios";

interface OrderItem {
  id: string;
  offerId: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
}

interface Order {
  id: string;
  customerId: string;
  guestInfo?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  items: OrderItem[];
  status: string;
  totalAmount: number;
}

interface Offer {
  id: string;
  name: string;
  description: string;
  displayImageReferences: string[];
  discount?: number;
  totalPrice: number;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

const CheckoutPage: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [offers, setOffers] = useState<{ [key: string]: Offer }>({});
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/orders/${orderId}/checkout`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching client secret:", error);
      setError("An error occurred while preparing the checkout. Please try again.");
      return null;
    }
  }, [orderId]);

  useEffect(() => {
    const fetchOrderAndOffers = async () => {
      setIsLoading(true);
      try {
        // Fetch order details
        const orderResponse = await axios.get(`${API_BASE_URL}/v1/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
        });
        setOrder(orderResponse.data);

        // Fetch offer details for each item
        const offerPromises = orderResponse.data.items.map((item: OrderItem) =>
          axios.get(`${API_BASE_URL}/v1/catalog/offers/${item.offerId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
          })
        );
        const offerResponses = await Promise.all(offerPromises);
        const offerData = offerResponses.reduce((acc, response) => {
          acc[response.data.id] = response.data;
          return acc;
        }, {});
        setOffers(offerData);
        
        // Fetch client secret
        const secret = await fetchClientSecret();
        setClientSecret(secret);
      } catch (error) {
        console.error("Error fetching order, offers, or client secret:", error);
        setError("An error occurred while loading the checkout. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (orderId) {
      fetchOrderAndOffers();
    }
  }, [orderId, fetchClientSecret]);

  if (isLoading) {
    return <div className="w-full h-[90%wh] flex flex-row items-center justify-center mt-[35vh] text-center font-semilight text-main-teal text-5xl -ml-3 mq780:text-11xl font-subhead p-4">Initializing Checkout...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const options = { clientSecret };

  return (
    <div className="w-full min-h-screen relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-start text-mini text-dark-navy font-subhead">
      <Header />
      <main className="flex-grow min-h-[80vh] mt-[100px] mq780:mt-0 flex flex-col mq780:flex-row">
        <div className="py-5 mx-6 mb-20 lg:mx-12 mq780:w-1/2">
          <h2 className="hidden text-2xl font-bold mb-4 text-center">Order Summary</h2>
          <div className="hidden mt-8 mq780:max-w-md"> 
            <h3 className="text-xl font-semibold mb-4">Order Summary</h3> {/* Hidden! */}
            {order && order.items.map((item) => {
              const offer = offers[item.offerId];
              // console.log(offer);
              return offer ? (
                <div key={item.id} className="flex justify-between items-center mb-2">
                  <span className="text-zinc-800">{offer.name} (x{item.quantity})</span>
                  <span>${(item.totalPrice * item.quantity).toFixed(2)}</span>
                </div>
              ) : null;
            })}
            <div className="border-t border-gray-300 my-4 pt-4 mb-10 flex justify-between items-center font-bold">
              <span>Total</span>
              <span>${(order?.totalAmount)?.toFixed(2)}</span>
            </div>
          </div>
          {clientSecret && (
            <div id="checkout">
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          )}
        </div>
        <div className="py-5 mx-6 lg:mx-12 mq780:w-1/2">
          <h2 className="text-2xl font-bold">Order Items</h2>
          {order && order.items.map((item) => {
            const offer = offers[item.offerId];
            return offer ? (
              <CheckoutItem
                key={item.id}
                offerID={item.offerId}
                orderDate={new Date().toLocaleDateString()}
                title={offer.name}
                image={offer.displayImageReferences[0] || ''}
                description={offer.description}
                isMobile={false}
                price={item.totalPrice}
              />
            ) : null;
          })}
        </div>
      </main>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default CheckoutPage;