import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, MapPin, Calendar, Filter } from "lucide-react";
import CalendarDateRange from "../General/CalendarDateRange";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Category {
  id: string;
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}

interface SearchParams {
  query?: string;
  categories?: string[];
  highlights?: string[];
  priceRange?: string;
  dealType?: string;
  startDate?: string;
  endDate?: string;
  latitude?: number;
  longitude?: number;
  page?: number;
  size?: number;
  location?: string;
  maxDistance?: number;
}

interface FilterDropdownProps {
  closeFunction: () => void;
  updateSearchParams: (params: Partial<SearchParams>) => void;
  currentSearchParams: SearchParams;
}

const locations = [
  "Houston, TX",
  "Fort Myers, FL",
  "Orlando, FL"
];


const categories = [
  "Food & Drinks",
  "Nature",
  "Nightlife",
  "Shopping & Fashion",
  "Classes & Workshops",
  "Museums, History & Culture",
  "Water Activities",
  "Sports",
  "Tours & Excursions",
  "Entertainment & Leisure",
];

const priceRanges = ["Free", "$", "$$", "$$$"];

const accommodations = [
  "Wheel Chair Accessibility",
  "Pets allowed",
  "Children allowed",
  "Free WiFi",
  "Assistive Devices",
  "Paratransit Service",
];

const DealsAndCoupons = [
  "Only Deals",
];

interface FilterOption {
  label: string;
  isOpen: boolean;
}

interface FilterInterface {
  closeFunction: () => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ closeFunction, updateSearchParams, currentSearchParams }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([
    { label: 'Distance', isOpen: false },
    { label: 'Categories', isOpen: false },
    { label: 'Price Range', isOpen: false },
    { label: 'Deals & Coupons', isOpen: false },
    { label: 'Highlights', isOpen: false },
  ]);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>(currentSearchParams.categories || []);
  const [selectedHighlights, setSelectedHighlights] = useState<string[]>(currentSearchParams.highlights || []);
  const [selectedPriceRange, setSelectedPriceRange] = useState<string | null>(currentSearchParams.priceRange || null);
  const [selectedDeals, setSelectedDeals] = useState<string[]>(currentSearchParams.dealType ? ["Only Deals"] : []);
  const [startDate, setStartDate] = useState<Date | null>(currentSearchParams.startDate ? new Date(currentSearchParams.startDate) : null);
  const [endDate, setEndDate] = useState<Date | null>(currentSearchParams.endDate ? new Date(currentSearchParams.endDate) : null);
  const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem("userLocation") || "Fort Myers, FL");
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [calendarShown, setCalendarShown] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [showAllHighlights, setShowAllHighlights] = useState(false);
  const navigate = useNavigate();
  const locationRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedDistance, setSelectedDistance] = useState<number>(100);
  const [isSliding, setIsSliding] = useState(false);

  const distancePresets = [
    { label: "5", value: 5 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 }
  ];

  const handleDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setSelectedDistance(value);
    if (!isSliding) {
      updateSearchParams({ maxDistance: value });
    }
  };

  const handleSliderMouseDown = () => setIsSliding(true);
  
  const handleSliderMouseUp = () => {
    setIsSliding(false);
    updateSearchParams({ maxDistance: selectedDistance });
  };

  const handlePresetClick = (value: number) => {
    setSelectedDistance(value);
    updateSearchParams({ maxDistance: value });
  };

  const toggleCategory = (categoryId: string) => {
    setSelectedCategories(prevSelected => {
      const newSelected = prevSelected.includes(categoryId)
        ? prevSelected.filter(id => id !== categoryId)
        : [...prevSelected, categoryId];
      return newSelected;
    });
  };
  
  const toggleHighlight = (highlightId: string) => {
    setSelectedHighlights(prevSelected => {
      const newSelected = prevSelected.includes(highlightId)
        ? prevSelected.filter(id => id !== highlightId)
        : [...prevSelected, highlightId];
      return newSelected;
    });
  };

  const selectLocation = (location: string) => {
    setSelectedLocation(location);
    localStorage.setItem('userLocation', location);
    
    const coordinates = {
      "Fort Myers, FL": { lat: 26.64092334701931, lng: -81.86055782194205 },
      "Orlando, FL": { lat: 28.538381666104296, lng: -81.37892813045282 },
      "Houston, TX": { lat: 29.760075777083394, lng: -95.3698 }
    };
    
    const selectedCoords = coordinates[location as keyof typeof coordinates];
    localStorage.setItem('userLatitude', selectedCoords.lat.toString());
    localStorage.setItem('userLongitude', selectedCoords.lng.toString());
    
    setIsLocationDropdownOpen(false);
    
    window.dispatchEvent(new Event('locationChange'));
    
    updateSearchParams({
      location: location,
      latitude: selectedCoords.lat,
      longitude: selectedCoords.lng
    });
  };

  const handleDateSelect = (date: Date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date > startDate) {
      setEndDate(date);
      updateSearchParams({
        startDate: startDate.toISOString(),
        endDate: date.toISOString()
      });
      setCalendarShown(false);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const toggleSelection = (item: string, state: string[], setState: React.Dispatch<React.SetStateAction<string[]>>) => {
    setState(prevState => {
      const newState = prevState.includes(item)
        ? prevState.filter(i => i !== item)
        : [...prevState, item];
      if (item === "Only Deals") {
        updateSearchParams({ dealType: newState.includes("Only Deals") ? "onlyDeals" : undefined });
      } else if (priceRanges.includes(item)) {
        updateSearchParams({ priceRange: newState.join(',') });
      }
      return newState;
    });
  };

  const handlePriceRangeChange = (range: string) => {
    if (selectedPriceRange === range) {
      setSelectedPriceRange(null);
      updateSearchParams({ priceRange: undefined });
    } else {
      setSelectedPriceRange(range);
      updateSearchParams({ priceRange: range });
    }
  };

  const handleCustomPriceRangeSubmit = () => {
    if (minPrice || maxPrice) {
      const priceRange = `${minPrice || ''}-${maxPrice || ''}`;
      updateSearchParams({ priceRange });
      setSelectedPriceRange(null);
    }
  };

  const handlePriceRangeSubmit = () => {
    updateSearchParams({ priceRange: `${minPrice}-${maxPrice}` });
  };

  const clearAllFilters = () => {
    setSelectedCategories([]);
    setSelectedPrices([]);
    setSelectedHighlights([]);
    setSelectedDeals([]);
    setSelectedPriceRange(null);
    setMinPrice("");
    setMaxPrice("");
    setStartDate(null);
    setEndDate(null);
    setSelectedDistance(100);
    updateSearchParams({
      categories: undefined,
      highlights: undefined,
      priceRange: undefined,
      dealType: undefined,
      startDate: undefined,
      endDate: undefined
    });
    closeFunction();
    navigate("/map");
  };

  const applyFilters = () => {
    updateSearchParams({
      categories: selectedCategories,
      highlights: selectedHighlights,
      priceRange: selectedPriceRange || undefined,
      dealType: selectedDeals.includes("Only Deals") ? "onlyDeals" : undefined,
      startDate: startDate ? startDate.toISOString() : undefined,
      endDate: endDate ? endDate.toISOString() : undefined,
      maxDistance: selectedDistance
    });
    closeFunction();
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Category[]>(`${API_BASE_URL}/v1/catalog/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    const fetchHighlights = async () => {
      try {
        const response = await axios.get<Highlight[]>(`${API_BASE_URL}/v1/catalog/highlights`);
        setHighlights(response.data);
      } catch (error) {
        console.error('Failed to fetch highlights:', error);
      }
    };

    fetchHighlights();
    fetchCategories();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (locationRef.current && !locationRef.current.contains(event.target as Node)) {
        setIsLocationDropdownOpen(false);
      }
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setCalendarShown(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsLocationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOption = (index: number) => {
    setFilterOptions(prevOptions =>
      prevOptions.map((option, i) =>
        i === index ? { ...option, isOpen: !option.isOpen } : option
      )
    );
  };

  const toggleLocationDropdown = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
  };

  const handleMonthChange = (offset: number) => {
    const newDate = new Date(currentYear, currentMonth + offset);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
  };

  const toggleCalendar = () => {
    setCalendarShown(!calendarShown);
  };

  return (
    <div className="w-screen bg-white p-0 pb-8 shadow-md relative overflow-y-auto">
      <div className="flex justify-between items-center mx-4 my-2">
        <button onClick={closeFunction} className="text-gray-500 pt-2 bg-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-semibold">Filters</h2>
        <div className="w-6" />
      </div>
      <div className='h-[1px] mx-0 mb-8 bg-zinc-300 w-full' />
      <div className="space-y-6 px-4">
        <div ref={dropdownRef} className="relative">
          <div
            onClick={toggleLocationDropdown}
            className="flex items-center bg-white rounded-md p-2 border border-solid border-blue-900 cursor-pointer"
          >
            <MapPin className="text-main-teal mr-2" size={30} />
            <input
              type="text"
              readOnly
              value={selectedLocation}
              className="bg-transparent text-zinc-900 w-full text-lg border-none outline-none text-copy cursor-pointer"
            />
            <ChevronDown className="text-main-teal ml-2" size={20} />
          </div>
          {isLocationDropdownOpen && (
            <div className="absolute w-full mt-1 bg-white border border-solid border-blue-900 rounded-md shadow-lg z-10">
              {locations.map((location, index) => (
                <div
                  key={index}
                  onClick={() => selectLocation(location)}
                  className="text-start p-2 hover:bg-blue-100 bg-zinc-50 cursor-pointer text-zinc-900 text-lg rounded-md"
                >
                  {location}
                </div>
              ))}
            </div>
          )}
        </div>
        <div onClick={toggleCalendar} className="flex items-center bg-white rounded-md p-2 border border-solid border-blue-900 cursor-pointer">
          <Calendar className="text-main-teal mr-2" size={30} />
          <input
            type="text"
            readOnly
            placeholder="Select date range"
            value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ""}
            className="bg-transparent w-full text-zinc-900 text-lg border-none outline-none text-copy mt-[2px]"
          />
        </div>
        {calendarShown && (<div className='max-w-[450px]'>
            <CalendarDateRange
              startDate={startDate}
              endDate={endDate}
              onDateSelect={handleDateSelect}
              year={currentYear}
              month={currentMonth}
              onMonthChange={handleMonthChange}
            />
          </div>
        )}
        {filterOptions.map((option, index) => (
          <div key={option.label}>
            <div className='h-[1px] mb-5 bg-zinc-300 w-full' />
            <div className="text-xl bg-white pb-0">
              <button
                className="flex justify-between items-center bg-white w-full text-left"
                onClick={() => toggleOption(index)}
              >
                <span className="text-main-teal text-5xl font-semibold">{option.label}</span>
                <ChevronDown className={`cursor-pointer text-main-teal transition-transform ${option.isOpen ? 'transform rotate-180' : ''}`} size={45} />
              </button>
            </div>
            {option.isOpen && (
              <div className="mt-2">
                {option.label === 'Distance' && (
                  <div className="mt-4 px-2 w-full">
                    <div className="flex flex-col pr-4 ml-2">
                      <div className="text-xl font-semibold text-blue-900 mb-4">
                        Within {selectedDistance} miles
                      </div>
                
                      <div className="w-full pr-2">
                        <input
                          type="range"
                          min="1"
                          max="100"
                          value={selectedDistance}
                          onChange={handleDistanceChange}
                          onMouseDown={handleSliderMouseDown}
                          onMouseUp={handleSliderMouseUp}
                          onTouchStart={handleSliderMouseDown}
                          onTouchEnd={handleSliderMouseUp}
                          className="w-[95%] h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer
                            [&::-webkit-slider-thumb]:appearance-none
                            [&::-webkit-slider-thumb]:w-5
                            [&::-webkit-slider-thumb]:h-5
                            [&::-webkit-slider-thumb]:rounded-full
                            [&::-webkit-slider-thumb]:bg-blue-900
                            [&::-webkit-slider-thumb]:cursor-pointer
                            [&::-moz-range-thumb]:w-5
                            [&::-moz-range-thumb]:h-5
                            [&::-moz-range-thumb]:rounded-full
                            [&::-moz-range-thumb]:bg-blue-900
                            [&::-moz-range-thumb]:cursor-pointer
                            [&::-moz-range-thumb]:border-0"
                        />
                      </div>
                
                      <div className="flex justify-start gap-2 mt-4">
                        {distancePresets.map((preset) => (
                          <button
                            key={preset.value}
                            onClick={() => handlePresetClick(preset.value)}
                            className={`min-w-[48px] px-2 py-1.5 rounded-lg text-sm transition-colors ${
                              selectedDistance === preset.value
                                ? 'bg-blue-900 text-white'
                                : 'bg-gray-100 text-blue-900 hover:bg-gray-200'
                            }`}
                          >
                            {preset.label}mi
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {option.label === 'Categories' && (
                  categories.map((category) => (
                    <div
                      key={category.id}
                      className={`cursor-pointer text-lg ml-2 pb-2 text-start ${
                        selectedCategories.includes(category.id) ? "text-blue-800 font-semibold" : ""
                      }`}
                      onClick={() => toggleCategory(category.id)}
                    >
                      {category.name}
                    </div>
                  ))
                )}
                {option.label === 'Price Range' && (
                  <>
                    {[
                      { label: "Free", value: "FREE" },
                      { label: "$", value: "$" },
                      { label: "$$", value: "$$" },
                      { label: "$$$", value: "$$$" },
                    ].map(({ label, value }) => (
                      <div key={value} className="flex items-center mb-2 text-lg ml-2">
                        <input
                          type="checkbox"
                          checked={selectedPriceRange === value}
                          onChange={() => handlePriceRangeChange(value)}
                          className="mr-2"
                        />
                        <label>{label}</label>
                      </div>
                    ))}
                    <div className="flex items-center mt-6 text-lg ml-1">
                      <input
                        type="number"
                        placeholder="Min"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        className="w-20 mr-2 p-2 border rounded"
                      />
                      <input
                        type="number"
                        placeholder="Max"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        className="w-20 mr-2 p-2 border rounded"
                      />
                      <button onClick={handleCustomPriceRangeSubmit} className="bg-main-teal text-white px-6 py-2 rounded">
                        Go
                      </button>
                    </div>
                  </>
                )}
                {option.label === 'Deals & Coupons' && (
                  DealsAndCoupons.map(deal => (
                    <div key={deal} className="flex items-center mb-2 text-lg ml-2">
                      <input
                        type="checkbox"
                        id={deal}
                        checked={selectedDeals.includes(deal)}
                        onChange={() => toggleSelection(deal, selectedDeals, setSelectedDeals)}
                        className="mr-2"
                      />
                      <label htmlFor={deal}>{deal}</label>
                    </div>
                  ))
                )}
                {option.label === 'Highlights' && (
                  <div>
                    {highlights.slice(0, showAllHighlights ? highlights.length : 5).map((highlight) => (
                      <div key={highlight.id} className="flex items-center mb-2 text-lg ml-2">
                        <input
                          type="checkbox"
                          id={highlight.id}
                          checked={selectedHighlights.includes(highlight.id)}
                          onChange={() => toggleHighlight(highlight.id)}
                          className="mr-2"
                        />
                        <label htmlFor={highlight.id}>{highlight.name}</label>
                      </div>
                    ))}
                    {highlights.length > 5 && (
                      <div className="flex flex-row">
                        <button
                          className="text-xl text-start bg-white ml-1 text-blue-500 cursor-pointer"
                          onClick={() => setShowAllHighlights(!showAllHighlights)}
                        >
                          {showAllHighlights ? (
                            <div className="flex flex-row gap-2 pt-3 -ml-1">
                              <p className="m-0 mt-[2px]">Show less</p> <ChevronUp className="inline" size={24} />
                            </div>
                          ) : (
                            <div className="flex flex-row gap-2 -ml-1">
                              <p className="m-0 mt-[2px]">Show more</p> <ChevronDown className="inline" size={24} />
                            </div>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="px-4 flex flex-row gap-3 pb-16 items-center justify-center">
        <button
          className="text-lg cursor-pointer whitespace-nowrap mt-10 pb-2 pt-2.5 px-3 bg-white text-steelblue border border-steelblue rounded-lg font-bold hover:bg-steelblue hover:text-white transition-colors"
          onClick={clearAllFilters}
        >
          Clear All Filters
        </button>
        <button
          className="text-lg cursor-pointer mt-10 flex flex-row pb-2 pt-2.5 px-10 bg-blue-900 text-zinc-100 border border-blue-800 bg-blue-800 rounded-lg font-bold hover:border-white transition-colors"
          onClick={applyFilters}
        >
          <Filter className="stroke-zinc-100 h-4 w-4 pr-2 mt-[2px]" />
          Apply
        </button>
      </div> 
    </div>
  );
};

export default FilterDropdown;