import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { fetchPromotions, applyPromotionsToOffers } from '../../../utils/promotionUtils';
import Header from '../../../components/User/Header';
import UserFooter from '../../../components/User/UserFooter';
import Rounder from '../../../components/General/Rounder';
import MobileRounderDynamic from '../../../components/Mobile/MobileRounderDynamic';
import DynamicTitleWrapper from '../../../components/General/DynamicTitleWrapper';
import { DateTime } from 'luxon';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface MerchantLandingPage {
  businessName: string;
  highlights: { id: string; name: string }[];
  categories: { id: string; name: string }[];
  description: string;
  displayImageReferences: string[];
  profilePictureReference: string;
  openingHours: {
    schedule: {
      [key: string]: {
        openTime: string;
        closeTime: string;
        closed: boolean;
      };
    };
  };
  offers: Offer[];
  createdAt: string;
}

interface Promotion {
  id: string;
  type: 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE';
  discountValue: number;
  minimumPurchaseAmount?: number;
  offerIds: string[];
}

interface Offer {
  id: string;
  name: string;
  description: string;
  price: number;
  locations: {
    latitude: number;
    longitude: number;
  }[];
  categories: { id: string; name: string }[];
  displayImageReferences: string[];
  startDate: string;
  endDate: string;
  promotionType?: string;
  percentageDiscount?: number;
  minimumPurchaseAmount?: number;
  discountValue?: number;
}

const BusinessPage: React.FC = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [merchantData, setMerchantData] = useState<MerchantLandingPage | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const OFFERS_PER_PAGE = 20;

  const getTitle = () => {
    return merchantData ? `${merchantData.businessName} | DoYouWeekend` : 'Business Profile | DoYouWeekend';
  };

  const formatOpeningHours = (schedule: MerchantLandingPage['openingHours']['schedule']) => {
    const daysOrder = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    
    const formatTime = (time: string | undefined) => {
      if (!time) return '';
      return DateTime.fromISO(time)
        .toLocaleString({
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
        .toUpperCase(); // To ensure AM/PM is uppercase
    };
  
    return daysOrder.map(day => {
      const hours = schedule[day];
      if (!hours || hours.closed) {
        return `${day.charAt(0) + day.slice(1).toLowerCase()}: Closed`;
      } else {
        const openTime = formatTime(hours.openTime);
        const closeTime = formatTime(hours.closeTime);
        return `${day.charAt(0) + day.slice(1).toLowerCase()}: ${openTime} - ${closeTime}`;
      }
    });
  };

  const fetchMerchantData = async (pageNumber: number = 0, isLoadingMore: boolean = false) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`${API_BASE_URL}/v1/merchants/${merchantId}/landing-page`, {
        params: {
          page: pageNumber,
          size: OFFERS_PER_PAGE
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      let newData = response.data;
      const offers = newData.offers.content; // Extract offers from content array
  
      // Fetch promotions for offers
      if (offers && offers.length > 0) {
        const offerIds = offers.map((offer: Offer) => offer.id);
        const promotions = await fetchPromotions(offerIds);
        const offersWithPromotions = applyPromotionsToOffers(offers, promotions);
  
        if (isLoadingMore) {
          // Append new offers to existing ones
          setMerchantData(prevData => ({
            ...prevData!,
            offers: [...(prevData?.offers || []), ...offersWithPromotions]
          }));
        } else {
          // Set initial data including merchant info and first page of offers
          setMerchantData({
            ...newData,
            offers: offersWithPromotions // Replace offers object with just the array of offers
          });
        }
  
        // Update hasMore based on pagination info
        setHasMore(pageNumber < newData.offers.totalPages - 1);
      } else {
        if (!isLoadingMore) {
          // If no offers, still set the merchant data without offers
          setMerchantData({
            ...newData,
            offers: [] // Set empty array for offers
          });
        }
        setHasMore(false);
      }
  
      setIsLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      console.error('Error fetching merchant data:', error);
      setError('Failed to load merchant data. Please try again later.');
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchMerchantData(0, false);
  }, [merchantId]);

  const handleScroll = useCallback(() => {
    const footerHeight = 700; // Approximate footer height
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight - footerHeight;
    const scrollPosition = windowHeight + scrollY;
    const scrollThreshold = documentHeight - 100;
  
    if (
      scrollPosition >= scrollThreshold && 
      !isLoadingMore && 
      hasMore && 
      !isLoading
    ) {
      setIsLoadingMore(true);
      setPage(prevPage => prevPage + 1);
      fetchMerchantData(page + 1, true);
    }
  }, [page, isLoadingMore, hasMore, isLoading, merchantId]);
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (isLoading) {
    return <div className="font-subhead text-main-teal text-3xl p-8">Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  return (
    <DynamicTitleWrapper
      getTitle={getTitle}
      description={`View business profile and offers for ${merchantData?.businessName || 'a merchant'} on DoYouWeekend`}
      path={`/business/${merchantId}`}
    >
    {/* Desktop view */}
      <div className="hidden mq1050:flex flex-col min-h-screen overflow-x-hidden w-full">
        <Header />
        <main className="flex-1 flex flex-row w-full">
            <div className="flex-1 p-8">
            {merchantData && (
            <div className="mb-8 bg-zinc-50 justify-between font-subhead gap-6 flex flex-row bg-white p-6 rounded-lg shadow-md">
                <div className='flex flex-row gap-6 min-w-[25%] max-w-[35%]'>
                    <img
                        src={merchantData.profilePictureReference || "/business-icon.png"}
                        alt={merchantData.businessName}
                        className="w-40 h-40 object-cover rounded-full mb-4 cursor-pointer"
                    />
                  {/* Desktop view categories and highlights section */}
                  <div className='flex flex-col'>
                    <h1 className="text-3xl font-bold mb-4">{merchantData.businessName}</h1>
                    <div className="mb-4">
                      <div className="flex flex-wrap gap-2">
                        {(showAllTags ? merchantData.categories : merchantData.categories.slice(0, 3)).map((category) => (
                          <span key={category.id} className="bg-blue-200 px-2 py-1 rounded">
                            {category.name}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-wrap gap-2">
                        {(showAllTags ? merchantData.highlights : merchantData.highlights.slice(0, 3)).map((highlight) => (
                          <span key={highlight.id} className="bg-blue-200 px-2 py-1 rounded">
                            {highlight.name}
                          </span>
                        ))}
                      </div>
                    </div>
                    {(merchantData.categories.length + merchantData.highlights.length > 5) && (
                      <button 
                        onClick={() => setShowAllTags(!showAllTags)}
                        className="text-main-teal hover:text-darkslateblue font-medium py-2 rounded-lg w-[60%] bg-zinc-50/2 cursor-pointer mt-1"
                      >
                        {showAllTags ? 'Show less' : 'Show all amenities'}
                      </button>
                    )}
                  </div>
                </div>
                <div className="mb-4 w-[50%]">
                    <h2 className="text-xl font-semibold mb-2">About Us:</h2>
                    <p className="mb-4 whitespace-prewrap leading-6">{merchantData.description}</p>
                </div>
                <div className="mb-4 min-w-[min(20%,260px)] ml-2">
                    <h2 className="text-xl font-semibold mb-2">Opening Hours</h2>
                    {formatOpeningHours(merchantData.openingHours.schedule).map((formattedHours, index) => (
                        <p key={index} className="text-sm">{formattedHours}</p>
                    ))}
                </div>
            </div>
            )}
            <h2 className="text-11xl font-bold mb-4 font-subhead">Offers</h2>
            {/* Desktop view offers section */}
            <div className="flex flex-wrap justify-start gap-8">
              {merchantData?.offers && merchantData.offers.length > 0 ? (
                merchantData.offers.map((offer) => (
                  <div key={offer.id} className="w-[calc(25% - 18px)]">
                    <Rounder
                      id={offer.id}
                      images={offer.displayImageReferences}
                      name={offer.name}
                      categories={offer.categories.map(cat => cat.name)}
                      price={offer.price}
                      longitude={offer.locations[0]?.longitude ?? 0}
                      latitude={offer.locations[0]?.latitude ?? 0}
                      promotionType={offer.promotionType}
                      percentageDiscount={offer.percentageDiscount}
                      buy_x={offer.minimumPurchaseAmount}
                      get_y={offer.discountValue}
                    />
                  </div>
                ))
              ) : (
                <div className='text-xl text-neutral font-subhead pb-8'>
                  No offers available from this merchant yet!
                </div>
              )}
            </div>
            {isLoadingMore && (
              <div className="w-full flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            )}
          </div>
        </main>
        <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
        />
      </div>

      {/* Mobile view */}
      <div className="mq1050:hidden font-subhead">
        <Header />
        <main className="p-4 min-h-[80vh]">
        {merchantData && (
          <div className="mt-[73px] mq780:mt-0 mb-4 bg-zinc-50 font-subhead bg-white p-6 rounded-lg shadow-md relative">
            <div className='flex flex-row gap-6 items-center mb-4'>
              <img
                src={merchantData.profilePictureReference || "/business-icon.png"}
                alt={merchantData.businessName}
                className="w-24 h-24 rounded-full cursor-pointer"
              />
              <div className='flex flex-col'>
                <h1 className="text-xl font-bold mb-2 -mt-4">{merchantData.businessName}</h1>
                <div className="flex flex-wrap gap-2">
                  {merchantData.categories.map((category) => (
                    <span key={category.id} className="bg-blue-200 px-2 py-1 rounded text-sm">
                      {category.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            
            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="my-8">
                    <h2 className="text-xl font-semibold mb-2">About Us:</h2>
                    <p className="mb-4 whitespace-pre-wrap leading-6">{merchantData.description}</p>
                  </div>
                  <div className="mb-4">
                    <h2 className="text-xl font-semibold mb-2">Opening Hours</h2>
                    {formatOpeningHours(merchantData.openingHours.schedule).map((formattedHours, index) => (
                      <p key={index} className="text-sm">{formattedHours}</p>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="bg-main-teal h-[40px] text-white rounded-full p-2 focus:outline-none"
                aria-label={isExpanded ? "Collapse details" : "Expand details"}
              >
                {isExpanded ? (
                  <ChevronUp className="w-6 h-6" />
                ) : (
                  <ChevronDown className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        )}
        {/* Mobile view offers section */}
        <h2 className="text-2xl font-bold mb-4 mt-8">Offers</h2>
        <div className="grid grid-cols-2 gap-4">
          {merchantData?.offers && merchantData.offers.length > 0 ? (
            merchantData.offers.map((offer) => (
              <MobileRounderDynamic
                key={offer.id}
                id={offer.id}
                images={offer.displayImageReferences}
                name={offer.name}
                categories={offer.categories.map(cat => cat.name)}
                price={offer.price}
                longitude={offer.locations[0]?.longitude ?? 0}
                latitude={offer.locations[0]?.latitude ?? 0}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            ))
          ) : (
            <>
              <div className='text-xl text-neutral font-subhead pb-8 col-span-2 text-left'>
                No offers available from this merchant yet!
              </div>
              <div className='items-center justify-center'>
                <a href="/map" className='bg-blue-900 rounded-lg p-4 no-underline text-white text-center font-semibold font-subhead'>Back to offers</a>
              </div>
            </>
          )}
        </div>
        {isLoadingMore && (
          <div className="col-span-2 w-full flex justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
          </div>
        )}
        </main>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>
    </DynamicTitleWrapper>
  );
};

export default BusinessPage;