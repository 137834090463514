import { FunctionComponent, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Loader, CheckCircle2, AlertCircle, Camera } from "lucide-react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import QrScanner from "react-qr-scanner";
import axios from "axios";

const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api";

const ScanQRCode: FunctionComponent = () => {
    const [scannerKey, setScannerKey] = useState(0);
    const [scanState, setScanState] = useState({
        result: null as string | null,
        status: "idle" as
            | "idle"
            | "scanning"
            | "processing"
            | "success"
            | "error",
        error: null as string | null,
        hasPermission: null as boolean | null,
        isCameraActive: false,
        isSecureContext: window.isSecureContext,
        showMessage: false,
        fadeIn: false,
        isReady: true,
    });

    const location = useLocation();

    useEffect(() => {
        if (scanState.showMessage) {
            const timer = setTimeout(() => {
                setScanState((prev) => ({ ...prev, fadeIn: true }));
            }, 50);
            return () => clearTimeout(timer);
        }
    }, [scanState.showMessage]);

    useEffect(() => {
        if (!window.isSecureContext) {
            setScanState((prev) => ({
                ...prev,
                error: "Camera access requires HTTPS or localhost. Please ensure you are using a secure connection.",
                hasPermission: false,
            }));
            return;
        }

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setScanState((prev) => ({
                ...prev,
                error: "Camera access is not supported in your browser.",
                hasPermission: false,
            }));
            return;
        }
    }, []);

    useEffect(() => {
        let cameraStream: MediaStream | null = null;

        const activateCamera = async () => {
            if (!window.isSecureContext) return;

            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(
                    (device) => device.kind === "videoinput"
                );

                if (videoDevices.length === 0) {
                    throw new Error("No camera devices found");
                }

                const constraints = {
                    video: {
                        facingMode: { ideal: "environment" },
                    },
                };

                cameraStream = await navigator.mediaDevices.getUserMedia(
                    constraints
                );
                setScanState((prev) => ({
                    ...prev,
                    hasPermission: true,
                    isCameraActive: true,
                    error: null,
                }));
            } catch (err) {
                console.error("Camera error:", err);
                let errorMessage = "Failed to access camera. ";

                if (err instanceof Error) {
                    if (
                        err.name === "NotAllowedError" ||
                        err.name === "PermissionDeniedError"
                    ) {
                        errorMessage +=
                            "Please enable camera access and refresh the page.";
                    } else if (err.name === "NotFoundError") {
                        errorMessage += "No camera device found.";
                    } else if (
                        err.name === "NotReadableError" ||
                        err.name === "TrackStartError"
                    ) {
                        errorMessage +=
                            "Camera is in use by another application.";
                    } else {
                        errorMessage += err.message;
                    }
                }

                setScanState((prev) => ({
                    ...prev,
                    hasPermission: false,
                    error: errorMessage,
                }));
            }
        };

        if (location.pathname === "/merchant-scan-qr-code") {
            activateCamera();
        }

        return () => {
            if (cameraStream) {
                cameraStream.getTracks().forEach((track) => {
                    track.stop();
                });
                setScanState((prev) => ({ ...prev, isCameraActive: false }));
            }
        };
    }, [location]);

    const processQRCode = useCallback(async (qrData: string) => {
        if (!API_BASE_URL) {
            throw new Error("API URL not configured");
        }

        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
            throw new Error("Authentication required");
        }

        const formData = new FormData();
        const blob = new Blob([qrData], { type: "text/plain;charset=UTF-8" });
        formData.append("file", blob, "qrcode.txt");

        return axios.post(`${API_BASE_URL}/v1/orders/scan-qr`, formData, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                Accept: "application/json",
            },
        });
    }, []);

    const handleScan = useCallback(
        async (data: string | null) => {
            if (!data || scanState.status !== "idle") return;

            setScanState((prev) => ({
                ...prev,
                status: "processing",
                result: data,
                showMessage: true,
                fadeIn: false,
            }));

            try {
                const response = await processQRCode(data);
                setScanState((prev) => ({
                    ...prev,
                    status: "success",
                    error: null,
                    result:
                        response.data?.message ||
                        "QR Code processed successfully",
                }));
            } catch (error) {
                const errorMessage = axios.isAxiosError(error)
                    ? error.response?.data?.message || "Server error"
                    : "Failed to process QR code";

                setScanState((prev) => ({
                    ...prev,
                    status: "error",
                    error: errorMessage,
                }));
            }
        },
        [processQRCode, scanState.status]
    );

    const handleError = useCallback((error: Error) => {
        console.error("QR Scanner error:", error);
        setScanState((prev) => ({
            ...prev,
            status: "error",
            error: "Scanner error: Please try again",
            showMessage: true,
        }));
    }, []);

    const dismissMessage = useCallback(() => {
        setScanState((prev) => ({
            ...prev,
            fadeIn: false,
        }));

        setTimeout(() => {
            setScanState((prev) => ({
                ...prev,
                status: "idle",
                error: null,
                result: null,
                showMessage: false,
            }));
            setScannerKey((prev) => prev + 1);
        }, 300);
    }, []);

    const renderScannerOverlay = () => {
        if (scanState.status === "idle" && scanState.isReady) {
            return (
                <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-48 h-48 border-2 border-white">
                        <div className="absolute -top-1 -left-1 w-4 h-4 border-t-2 border-l-2 border-white" />
                        <div className="absolute -top-1 -right-1 w-4 h-4 border-t-2 border-r-2 border-white" />
                        <div className="absolute -bottom-1 -left-1 w-4 h-4 border-b-2 border-l-2 border-white" />
                        <div className="absolute -bottom-1 -right-1 w-4 h-4 border-b-2 border-r-2 border-white" />
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderStatusMessage = () => {
        if (!scanState.showMessage) return null;

        const messageContent = (() => {
            switch (scanState.status) {
                case "processing":
                    return (
                        <div className="flex flex-col items-center gap-4">
                            <Loader
                                className="animate-spin text-white"
                                size={32}
                            />
                            <span className="text-lg">
                                Processing QR Code...
                            </span>
                        </div>
                    );
                case "success":
                    return (
                        <div className="flex flex-col items-center gap-4">
                            <CheckCircle2
                                className="text-green-400"
                                size={48}
                            />
                            <p className="text-lg font-medium">
                                {scanState.result}
                            </p>
                            <button
                                onClick={dismissMessage}
                                className="mt-2 px-6 py-2 bg-white text-green-600 rounded-full font-medium hover:bg-green-50 transition-colors focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-600"
                            >
                                Scan Another
                            </button>
                        </div>
                    );
                case "error":
                    return (
                        <div className="flex flex-col items-center gap-4">
                            <AlertCircle className="text-coral" size={48} />
                            <p className="text-lg font-medium">
                                {scanState.error}
                            </p>
                            <button
                                onClick={dismissMessage}
                                className="mt-2 px-6 py-2 bg-white text-coral rounded-full font-medium hover:bg-red-50 transition-colors focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-coral"
                            >
                                Try Again
                            </button>
                        </div>
                    );
                default:
                    return null;
            }
        })();

        return (
            <div
                className={`absolute inset-0 bg-black/75 flex flex-col items-center justify-center text-white p-6 text-center transition-opacity duration-300 ${
                    scanState.fadeIn ? "opacity-100" : "opacity-0"
                }`}
            >
                {messageContent}
            </div>
        );
    };

    if (!scanState.isSecureContext) {
        return (
            <div className="flex flex-col min-h-screen">
                <MerchantHubHeader />
                <main className="flex-1 flex font-subhead flex-col items-center justify-center p-4">
                    <div className="max-w-md text-center">
                        <Camera
                            className="mx-auto mb-4 text-gray-400"
                            size={48}
                        />
                        <h1 className="text-2xl font-bold mb-4">
                            Camera Access Error
                        </h1>
                        <p className="text-coral font-medium">
                            Camera access requires HTTPS or localhost. Please
                            ensure you are using a secure connection.
                        </p>
                    </div>
                </main>
                <Footer
                    dYWLogoW1="/dyw-logow-1@2x.png"
                    propAlignSelf="stretch"
                    propPosition="unset"
                    propTop="unset"
                    propLeft="unset"
                    propWidth="unset"
                />
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <MerchantHubHeader />
            <main className="flex-1 flex font-subhead flex-col items-center justify-center p-4">
                <div className="w-full max-w-md space-y-6">
                    <h1 className="text-2xl font-bold text-center">
                        Scan QR Code
                    </h1>
                    {scanState.hasPermission === null ? (
                        <div className="text-center p-8">
                            <Loader
                                className="animate-spin mx-auto mb-4"
                                size={32}
                            />
                            <p className="text-gray-600">
                                Requesting camera permission...
                            </p>
                        </div>
                    ) : scanState.hasPermission && scanState.isCameraActive ? (
                        <div className="relative aspect-square w-full overflow-hidden rounded-lg shadow-lg bg-black">
                            <QrScanner
                                key={scannerKey}
                                onError={handleError}
                                onScan={handleScan}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                constraints={{
                                    video: {
                                        facingMode: { ideal: "environment" },
                                        width: { ideal: 640 },
                                        height: { ideal: 480 },
                                    },
                                }}
                                delay={500}
                            />
                            {renderScannerOverlay()}
                            {renderStatusMessage()}
                        </div>
                    ) : (
                        <div className="text-center p-8 bg-white rounded-lg shadow">
                            <AlertCircle
                                className="mx-auto mb-4 text-coral"
                                size={32}
                            />
                            <p className="text-coral font-medium">
                                {scanState.error}
                            </p>
                        </div>
                    )}
                    {scanState.status === "idle" && scanState.isReady && (
                        <p className="text-center text-gray-600">
                            Position the QR code within the frame to scan
                        </p>
                    )}
                </div>
            </main>
            <Footer
                dYWLogoW1="/dyw-logow-1@2x.png"
                propAlignSelf="stretch"
                propPosition="unset"
                propTop="unset"
                propLeft="unset"
                propWidth="unset"
            />
        </div>
    );
};

export default ScanQRCode;
